#ontable {

  width: 100%;
  height: 100%;
  background-image: url(../black-background-grey.png);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  overflow: hidden;

  .gametable {
    position: fixed;
    top: 4%;
    left: 50%;
    width: 75vw;
    height: 84vh;
    margin-left: -37vw;
    background-image: url(/static/media/new_table.051db900.png);
    background-size: 100% 105%;
  }

  // .seat {
  //   height: 150px;
  //   position: absolute;
  //   width: 150px;
  //   z-index: 99999999;
  // }

  #mydiv {
    position: fixed;
    top: 22vh;
    left: 50%;
    width: 38vw;
    height: 38vh;
    /* margin-top: -23.5vh; */
    margin-left: -18vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // #mydiv table tbody tr {
  //   // max-width: 40px;
  //   height: 30px !important;
  // }

  // #mydiv table tbody tr td {
  //   max-width: 40px;
  //   // max-height: 30px;
  // }

  //   td {
  //     display: table-cell;
  //     vertical-align: baseline;
  // }



  .seat0-button {
    left: 235px;
    top: 410px;
  }

  .seat .bet {
    top: -20px;
    width: auto;
    background-color: #000;
    padding: 3px 10px;
  }

  .bet {
    color: white;
    font-size: 12px;
    left: 35px;
    position: absolute;
    top: 0px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-shadow: -1px 0 1px black, 0 1px 1px black, 1px 0 1px black, 0 -1px 1px black;
    font-weight: bolder;
  }

  .my_card_div:hover .card_change_info {
    display: flex;
  }


  // .card:hover {
  //   transform: scale(1.0);
  //   z-index: 1;
  // }

  .holecards {
    float: left;
    // height: 75px;
    // width: 75px;
    left: 20px;
    // position: absolute;
    top: 3px;
    display: flex;
    flex-direction: row;
    z-index: 9999;
    margin-left: 0.5vw;
    // background-color: #000;
    // border-radius: 25px;
  }

  .card {
    position: absolute;
    z-index: -2;
    top: 5px
  }

  .card img {
    min-height: 7vh;
    min-width: 2vw;
    max-height: 13vh;
    max-width: 6vw;
  }

  // .holecard0 {
  //   left: 67px;
  // }

  // .holecard1 {
  //   left: 87px;
  // }

  // .holecard2 {
  //   left: 107px;
  // }

  // .holecard3 {
  //   left: 127px;
  // }

  .card img {
    border-radius: 4px;
    background-color: white;
  }

  .flop {
    position: absolute;
    top: 27vh;
    left: 32vw;
    z-index: 9999999999999999;
    display: flex;
    flex-direction: row;
    max-width: 15vw;
    flex-wrap: wrap;
  }

  .turn {
    position: absolute;
    top: 27vh;
    left: 48vw;
    z-index: 9999999999999999;
  }

  .river {
    position: absolute;
    top: 27vh;
    left: 54vw;
    z-index: 9999999999999999;
  }

  .pottotal {
    position: absolute;
    left: -5vw;
    z-index: 9999999999999999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    flex: 0.4;
  }

  .potetext {
    font-size: 11px;
    font-weight: bold;
    color: white;
  }

  .img__description_layer {
    top: 0px;
    border-radius: 5vw;
    border: 2px solid gray;
    width: 5.3vw;
    height: 5.3vw;
    position: absolute;
    visibility: visible;
    opacity: 1;
  }

  .img__description {
    position: absolute;
    top: 29%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: MontserratBold;
    color: #f6f6f6;
    font-size: 2vh;
    text-transform: uppercase;
  }

  .img_avatar_shadow {
    opacity: 0.2;
    -moz-animation: cssAnimationS 0s ease-in 2s forwards;
    -webkit-animation: cssAnimationS 0s ease-in 2s forwards;
    -o-animation: cssAnimationS 0s ease-in 2s forwards;
    animation: cssAnimationS 0s ease-in 2s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .img_avatar {
    opacity: 0.3
  }

  @keyframes cssAnimationS {
    to {
      opacity: 1;
      // overflow: hidden;
      // display: none;
      // visibility: hidden;
    }
  }

  @-webkit-keyframes cssAnimationS {
    to {
      opacity: 1;
      // visibility: hidden;
      // display: none;
      // visibility: hidden;
    }
  }

  .last_action {
    min-width: 20px;
    padding-left: 5px;
    padding-right: 5px;
    // padding: 2px;
    min-height: 20px;
    background-color: #000;
    position: absolute;
    left: -5px;
    top: 10px;
    z-index: 9999999;
  }

  .last_action p {
    font-weight: 900;
    color: rgb(131, 71, 71);
    font-size: 14px;
    margin-block-start: unset;
    margin-block-end: unset;
    margin-top: 3px;
  }

  .show_hide {
    -moz-animation: cssAnimation 0s ease-in 2s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 2s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 2s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 2s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @keyframes cssAnimation {
    to {
      width: 0;
      height: 0;
      overflow: hidden;
      display: none;
      visibility: hidden;
    }
  }

  @-webkit-keyframes cssAnimation {
    to {
      width: 0;
      height: 0;
      visibility: hidden;
      display: none;
      visibility: hidden;
    }
  }


  .remove_img_av {
    -moz-animation: cssAnimation 0s ease-out 5s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-out 5s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-out 5s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-out 5s forwards;
  }

  @keyframes cssAnimationOpa {

    // 0% {
    //   opacity: 0.3;
    // }
    // 50% {
    //   opacity: 0.3;
    // }
    // 75% {
    //   opacity: 0.3;
    // }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes cssAnimationOpa {

    // 0% {
    //   opacity: 0.3;
    // }
    // 50% {
    //   opacity: 0.3;
    // }
    // 75% {
    //   opacity: 0.3;
    // }
    to {
      opacity: 1;
    }
  }

  // .dealer {
  //   width: 16px;
  //   height: 16px;
  //   padding: 5px;
  //   border-radius: 100px;
  //   position: absolute;
  //   background-color: white;
  //   text-align: center;
  //   z-index: 99999;
  //   color: #000
  // }

  // .dealer.dealer0 {
  //   top: 50vh;
  //   left: 26vw;
  // }

  // .dealer.dealer1 {
  //   top: 30vh;
  //   left: 28vw;
  // }

  // .dealer.dealer2 {
  //   top: 23vh;
  //   right: 60vw;
  // }

  // .dealer.dealer3 {
  //   top: 23vh;
  //   left: 58vw;
  // }

  // .dealer.dealer4 {
  //   top: 30vh;
  //   right: 27vw;
  // }

  // .dealer.dealer5 {
  //   top: 23vh;
  //   left: 38vw;
  // }

  // .dealer.dealer6 {
  //   top: 52vh;
  //   right: 29vw;
  // }

  // .dealer.dealer7 {
  //   top: 30vh;
  //   right: 28vw;
  // }


  // .pot {
  //   width: 20px;
  //   height: 20px;
  //   padding: 3px;
  //   border-radius: 100px;
  //   position: absolute;
  //   // background-color: black;
  //   text-align: center;
  // }

  // .pot.pot0 {
  //   top: 50vh;
  //   left: 28vw;
  // }

  // .pot.pot1 {
  //   top: 30vh;
  //   left: 28vw;
  // }

  // .pot.pot2 {
  //   top: 56vh;
  //   left: 38vw;
  // }

  // .pot.pot3 {
  //   top: 56vh;
  //   right: 40vw;
  // }

  // .pot.pot4 {
  //   top: 19.5vh;
  //   left: 60vw;
  // }

  // .pot.pot5 {
  //   top: 23vh;
  //   left: 38vw;
  // }

  // .pot.pot6 {
  //   top: 52vh;
  //   right: 29vw;
  // }

  // .pot.pot7 {
  //   top: 30vh;
  //   right: 28vw;
  // }


  label {
    color: #fff
  }


  // @font-face {
  //   font-family: 'Oswald';
  //   src: url('http://wpas.com/html5/fonts/Oswald-Bold.eot');
  //   src: url('http://wpas.com/html5/fonts/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
  //       url('http://wpas.com/html5/fonts/Oswald-Bold.woff2') format('woff2'),
  //       url('http://wpas.com/html5/fonts/Oswald-Bold.woff') format('woff'),
  //       url('http://wpas.com/html5/fonts/Oswald-Bold.ttf') format('truetype');
  //   font-weight: bold;
  //   font-style: normal;
  // }

  /* CONTROL BAR */

  #ControlBar {
    width: 100vw;
    height: 12vh;
    max-height: 53px;
    background-image: url(http://wpas.com/html5/assets/bottom-bar-bg.png);
    position: absolute;
    bottom: 0;
    left: 0;
    background-size: auto;
    color: #fff;
  }

  #ControlBar .background {
    width: 100vw;
    height: 20vh;
    max-height: 80px;
    background-image: url(http://wpas.com/html5/assets/bottom-bar-right.png);
    position: absolute;
    bottom: 0;
    left: 0;
    background-size: 100% 100%;
  }

  #ControlBar #HandRank {
    position: absolute;
    display: block;
    bottom: 142px;
    left: 10px;
    height: 64px;
    width: 654px;
    background-image: url(http://wpas.com/html5/assets/hand-rank-bg.png);
    background-repeat: no-repeat;
    zoom: 0.5;
  }

  #ControlBar #HandRank.slider {
    overflow-y: hidden;
    max-height: 64px;
    /* approximate max height */

    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  #ControlBar #HandRank.slider.close {
    max-height: 0;
  }

  /*
    .tablet #ControlBar #HandRank.slider {
    transform: scale(0.9);
    }
    */
  #ControlBar #HandRank #RankText {
    display: inline-block;
    position: relative;
    float: left;
    text-align: center;
    font-size: 24px;
    margin-top: 14px;
    width: 224px;
    left: 20px;
  }

  #ControlBar #HandRank .RankProgressBar {
    display: inline-block;
    position: relative;
    background: black;
    width: 380px;
    height: 36px;
    margin-top: 14px;
    float: right;
    right: 15px;
    border-radius: 14px;
  }

  #ControlBar #HandRank .RankProgressBar .progressBar {
    width: 372px;
    height: 28px;
    border-radius: 10px;
    margin-top: 4px;
    margin-left: 4px;
  }

  #ControlBar #HandRank .RankProgressBar .progressBar.low {
    background-image: linear-gradient(to right, red, orange);
  }

  #ControlBar #HandRank .RankProgressBar .progressBar.med {
    background-image: linear-gradient(to right, orange, yellow);
  }

  #ControlBar #HandRank .RankProgressBar .progressBar.high {
    background-image: linear-gradient(to right, yellow, limegreen);
  }

  #ControlBar #signal {
    display: block;
    position: absolute;
    width: 140px;
    height: 50px;
    bottom: 108px;
    left: 10px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  #ControlBar #signal.connecting {
    background-image: url(http://wpas.com/html5/assets/signalConnecting.png);
  }

  #ControlBar #signal.excellent {
    background-image: url(http://wpas.com/html5/assets/signalExcellent.png);
  }

  #ControlBar #signal.good {
    background-image: url(http://wpas.com/html5/assets/signalGood.png);
  }

  #ControlBar #signal.poor {
    background-image: url(http://wpas.com/html5/assets/signalPoor.png);
  }

  #ControlBar #pnlChat {
    display: inline-block;
    position: absolute;
    width: 26vw;
    height: 278px;
    float: left;
    left: 26px;
    bottom: -270px;
  }

  html.desktop #ControlBar #pnlChat {
    width: 29vw;
    left: 0px;
  }

  #ControlBar #pnlChat .frame {
    background-image: url(http://wpas.com/html5/assets/tall-window-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }

  #ControlBar .btnContainer {
    position: absolute;
    left: 4px;
    display: none;
  }

  #ControlBar .btnContainer a.hidden {
    display: none;
  }

  #ControlBar .btnContainer a {
    display: inline-block;
    position: relative;
    width: auto;
    height: 36px;
    float: left;
    background-image: url(http://wpas.com/html5/assets/control-bet-blank.png);
    text-align: center;
    font-size: 21px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    z-index: 1;
  }

  #ControlBar .btnContainer a.active {
    background-image: url(http://wpas.com/html5/assets/control-bet-active.png);
  }

  /*
    #ControlBar #btnChat {
    background-image: url(../assets/button-chat.png);
    
    }
    */

  #ControlBar .btnContainer a .fa {
    margin: 7px;
    color: white;
  }

  #ControlBar #pnlChat #txtMSG {
    display: block;
    position: relative;
    width: calc(100% - 36px);
    height: 18px;
    bottom: 76px;
    left: 15px;
    background-color: white;
    border-radius: 6px;
    z-index: 0;
    font-size: 16px;
    color: dimgray;
  }

  html.mobile #ControlBar #pnlChat #txtMSG {
    bottom: 72px;
    left: 9px;
  }

  #ControlBar #pnlChat .frame #chatBox {
    background-color: rgba(0, 0, 0, 0.4);
    width: calc(98% - 28px);
    height: calc(100% - 56px);
    position: relative;
    left: calc(1% + 14px);
    top: 10px;
    border-radius: 6px;
    overflow-y: scroll;
  }

  #ControlBar #pnlChat .frame #chatBox #chatThread {
    font-size: 16px;
    padding-left: 6px;
    padding-right: 6px;
  }

  /*
    #ControlBar #pnlChat .frame #chatBox #chatThread {
    color: white;
    }
    */

  #ControlBar #pnlChat #btnClose {
    display: block;
    position: relative;
    width: 30px;
    height: 30px;
    float: right;
    bottom: 286px;
    background-image: url(http://wpas.com/html5/assets/button-close.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  #ControlBar #TableStatus {
    position: absolute;
    left: 30vw;
    width: calc(70vw - 10px);
    height: 46px;
    padding-right: 10px;
  }

  #ControlBar #TableStatus #CashGameOptions,
  #ControlBar #TableStatus #ShowMyCards,
  #ControlBar #TableStatus #RebuyOptions,
  #ControlBar #TableStatus #PrivateGame {
    display: inline-block;
    width: 28vw;
    vertical-align: top;
    padding-top: 8px;
    float: right;
  }

  #lblTableStatus {
    font-size: 1.2em;
    text-align: center;
    display: inline-block;
    width: 39vw;
    // height: 70px;
    vertical-align: top;
    padding-top: 14px;
  }

  #ControlBar #TableStatus #RunItTwiceOptions {
    display: inline-block;
    width: 28vw;
    vertical-align: top;
    padding-top: 8px;
    float: right;
  }

  #ControlBar #TableStatus #CashGameOptions #chipManagerButtons {
    display: flex;
  }


  #ControlBar #SittingOutControls {
    display: inline-block;
    width: 28vw;
    vertical-align: top;
    padding-top: 8px;
    right: 10px;
    position: absolute;
    padding-right: 10px;
  }

  #ControlBar #AutoControls,
  #ControlBar #AutoControls_CC,
  #ControlBar #ActionControls {
    position: absolute;
    right: 0px;
    bottom: 6px;
    z-index: 9999999;
  }

  #ControlBar #AutoControls_CC #btnAutoFold,
  #ControlBar #AutoControls #btnAutoFold,
  #ControlBar #AutoControls #btnAutoCheck,
  #ControlBar #AutoControls #btnAutoCall {
    display: inline-block;
    float: left;
    background-image: url(http://wpas.com/html5/assets/button-blue2.png);
    background-size: 100% 100%;
    min-width: 7vw;
    height: 44px;
    max-width: 15vw;
    text-align: center;
    color: #fff;
    font-size: 1em;
    line-height: 32px;
    margin-right: 8px;
    padding: 0px 5px;
  }

  @media screen and(max-width: 649px) {

    #ControlBar #AutoControls_CC #btnAutoFold,
    #ControlBar #AutoControls #btnAutoFold,
    #ControlBar #AutoControls #btnAutoCheck,
    #ControlBar #AutoControls #btnAutoCall {
      height: 30px;
      line-height: 20px;
      font-size: 0.7em;
      margin-top: 5px;
    }
  }

  @media screen and(min-width: 650px) and (max-width: 800px) {

    #ControlBar #AutoControls_CC #btnAutoFold,
    #ControlBar #AutoControls #btnAutoFold,
    #ControlBar #AutoControls #btnAutoCheck,
    #ControlBar #AutoControls #btnAutoCall {
      height: 35px;
      line-height: 25px;
      font-size: 0.7em;
    }
  }

  @media screen and(min-width: 800px) and (max-width: 850px) {

    #ControlBar #AutoControls_CC #btnAutoFold,
    #ControlBar #AutoControls #btnAutoFold,
    #ControlBar #AutoControls #btnAutoCheck,
    #ControlBar #AutoControls #btnAutoCall {
      height: 35px;
      line-height: 25px;
      font-size: 0.7em;
    }
  }

  #ControlBar #AutoControls_CC #btnAutoFold {
    background-image: url(http://wpas.com/html5/assets/button-red2.png);
    font-size: 1em;
    font-weight: bold;
    max-width: 40vw;
    // background-color: red;
  }

  #btnAutoFold:active {
    // background-color: orange;
    box-shadow: 0 5px green($color: #000000);
    transform: translateY(2px);
  }

  #ControlBar #AutoControls .checkmark {
    background-image: url(http://wpas.com/html5/assets/button-off.png);
    background-size: auto 100%;
    height: 14px;
    background-repeat: no-repeat;
    background-position-x: center;
    margin-top: -6px;
  }

  #ControlBar #AutoControls .checkmark.checked {
    background-image: url(http://wpas.com/html5/assets/button-on.png);
  }

  #ControlBar #ActionControls #btnFold,
  #ControlBar #ActionControls #btnCheckCall,
  #ControlBar #ActionControls #btnBetRaise {
    display: inline-block;
    float: left;
    background-size: 100% 100%;
    width: 20vw;
    height: 56px;
    max-width: 280px;
    text-align: center;
    color: #fff;
    font-size: 1.4em;
    line-height: 54px;
    margin-right: 8px;
  }

  #ControlBar a label.small {
    font-size: 13px;
    line-height: 8px;
    display: block;
    margin-top: 5px;
    height: 0px;

  }

  #ControlBar #ActionControls #btnCheckCall label {
    color: #FFF;
  }

  #ControlBar #ActionControls #btnBetRaise label {
    color: #fff;
    font-weight: bolder;
  }

  #ControlBar #ActionControls .amount {
    height: 14px;
    background-repeat: no-repeat;
    background-position-x: center;
    line-height: 36px;
  }

  #ControlBar #ActionControls #btnBetRaise .amount {
    color: #fff;
    font-weight: bolder;
  }

  #ControlBar #ActionControls #btnFold,
  .btnRed {
    background-image: url(http://wpas.com/html5/assets/button-red2.png);
  }

  #ControlBar #ActionControls #btnCheckCall,
  .btnBlue {
    background-image: url(http://wpas.com/html5/assets/button-blue2.png);
  }

  #ControlBar #ActionControls #btnBetRaise,
  .btnGreen {
    background-image: url(http://wpas.com/html5/assets/button-green4.png);
  }

  #ControlBar #TableStatus #CashGameOptions #btnAddChips,
  #ControlBar #TableStatus #CashGameOptions #btnMinusChips,
  #ControlBar #TableStatus #ShowMyCards #btnShowCards,
  #ControlBar #SittingOutControls #btnImBack,
  #ControlBar #TableStatus #btnRebuy,
  #ControlBar #TableStatus #btnStart {
    display: inline-block;
    float: right;
    background-size: 100% 100%;
    width: 46%;
    height: 56px;
    max-width: 280px;
    text-align: center;
    color: #fff;
    font-size: 1.1em;
    line-height: 32px;
    margin-left: 8px;
  }

  #ControlBar #TableStatus #RunItTwiceOptions a {
    display: inline-block;
    float: right;
    background-size: 100% 100%;
    width: 30%;
    height: 56px;
    max-width: 280px;
    text-align: center;
    color: #fff;
    font-size: 1.1em;
    line-height: 32px;
    margin-left: 8px;
  }

  #ControlBar #SittingOutControls #btnImBack label {
    color: #FFF;
  }

  #ControlBar #SittingOutControls #btnImBack {
    background-image: url(http://wpas.com/html5/assets/button-green4.png);
  }

  #ControlBar #TableStatus #CashGameOptions #btnAddChips label {
    color: #FFF;
  }

  #ControlBar #TableStatus #CashGameOptions #btnMinusChips label {
    color: #fff;
  }

  #ControlBar #TableStatus #CashGameOptions #btnAddChips {
    background-image: url(http://wpas.com/html5/assets/button-green4.png);
  }

  #ControlBar #TableStatus #CashGameOptions #btnMinusChips {
    background-image: url(http://wpas.com/html5/assets/button-red2.png);
  }

  #ControlBar #TableStatus #ShowMyCards #btnShowCards label {
    color: #fff;
  }

  #ControlBar #TableStatus #ShowMyCards #btnShowCards,
  #ControlBar #TableStatus #btnRebuy,
  #ControlBar #TableStatus #btnStart {
    background-image: url(http://wpas.com/html5/assets/button-green4.png);
  }

  #ControlBar #BetControls {
    padding-top: 7px;
  }

  #ControlBar #BetControls #btnBetMinus,
  #ControlBar #BetControls #btnBetPlus {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    display: block;
    height: 29px;
    width: 32px;
  }

  #ControlBar #BetControls #btnBetMinus {
    background-image: url(http://wpas.com/html5/assets/control-bet-minus.png);
  }

  #ControlBar #BetControls #btnBetPlus {
    background-image: url(http://wpas.com/html5/assets/control-bet-plus.png);
  }

  #ControlBar #BetControls #btnBetAmount {
    display: flex;
    flex-direction: row;
  }

  #ControlBar #BetControls #btnBetAmount input {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    width: 12vw;
    font-size: 1.3em;
    text-align: center;
    color: #031628;
    border: 2px solid #142142;
    -moz-box-shadow: inset 0 0 4px #000000;
    -webkit-box-shadow: inset 0 0 4px #000000;
    box-shadow: inset 0 0 4px #000000;
  }

  /* CONTROL BAR END */



  .info {
    width: 30vw;
    position: absolute;
    top: 12px;
    left: 10px;
    z-index: 1;
    margin: 0 auto;
    padding-top: 0px;
    color: #fff;
    display: flex;
    flex-direction: row;
    font-size: 12px;
  }

  .info .container {
    padding-left: 15px;
  }

  .gametype {
    width: 24vw;
    position: absolute;
    top: 72px;
    left: 50px;
    z-index: 1;
    margin: 0 auto;
    padding-top: 0px;
    color: #fff
  }

  #GameInfo {
    // right: 50px;
    position: absolute;
  }

  #GameInfo #Speed {
    transform: scale(0.6);
    /*width: 47px;
      height: 16px;*/
    margin-right: -6px;
    margin-left: -8px;
  }

  #GameInfo #lblTableName {
    font-size: 2vh;
    margin-bottom: -2px;
  }

  #GameInfo #lblSubTitle,
  #GameInfo #lblGameNo {
    font-size: 2vh;
    // line-height: 15px;
  }
}

.tablecus tbody tr td {
  // min-width: 4vh;
  // max-width: 4.3vw;
  // min-width: 4.3vw;
  width: 8vh;
}

#mydiv table tbody tr td img {
  // max-width: 4vw;
  // max-height: 10vh;
}

.sitinout {
  z-index: 9999;
  position: absolute;
  bottom: 80px;
  left: 5px;
  display: flex;
  flex-direction: column;
}

.sitinout div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sitinout_text {
  font-size: 2vh;
  color: #fff
}

// .tc_show {
//   animation: tc_show_cssAnimation 0s 2s forwards;
//   display: hidden;
// }

@keyframes tc_show_cssAnimation {
  to {
    display: block;
  }
}


#felt {
  // position: relative;
  margin: 0px;
  height: 100%;
  width: 100%;
  /* background: green; */
  /* background-image: repeating-linear-gradient(to top left, rgba(255,255,255,0), rgba(255,255,255,0) 80px, rgba(255,255,255,0.2) 80px, rgba(255,255,255,0.2) 150px); */
  /* box-shadow: inset 0 0 10px rgba(0,0,0,0.4); */
}

#felt div {
  position: absolute;
  z-index: 0;
  transition-property: left, top, -webkit-transform;
  transition-timing-function: ease-out, ease-out, ease-in-out;
  transition-duration: 0.5s;
}

#felt div img {
  width: 4.1vw;
}

#felt2 {
  margin: 0px;
  height: 100%;
  width: 100%;
}

#felt2 div {
  position: absolute;
  /* left: -140px; */
  z-index: 0;
  transition-property: left, top, -webkit-transform;
  transition-timing-function: ease-out, ease-out, ease-in-out;
  transition-duration: 0.5s;
}



.rotate-vert-center {
  -webkit-animation: rotate-vert-center 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) normal;
  animation: rotate-vert-center 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) normal;
}

@-webkit-keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }

  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

.resizer {
  // width: 10px;
  // height: 10px;
  // border-radius: 50%;
  /*magic to turn square into circle*/
  background: white;
  border: 1px solid #000;
  position: absolute;
}

.resizer.top_r {
  top: 0px;
  cursor: ns-resize;
  // transform: translate(-7px, 0px);
  width: 100%;
}

.resizer.right_r {
  right: 0px;
  // bottom: 50%;
  cursor: ew-resize;
  // transform: translate(0px, 5px);
  height: 100%;
}

.chatBox {
  position: absolute;
  left: 1px;
  bottom: 55px;
  min-width: 150px;
  // max-width: 300px;
  min-height: 175px;
  // max-height: 400px;
  height: 175px;
  border: 2px solid #000;
  // border-bottom: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-bottom: 30px;
  overflow: hidden;
  z-index: 999999999999999;
  .tab_row {
    display: flex;
    .chat_button {
      border-radius: 5px;
      border: 0.5px solid #fff;
      border-bottom: 0px;
      padding: 5px 3vw;
      background-color: black;
      cursor: pointer;
    }
    .tab_active{
      background-color: #fff;
      color: #000;
    }
  }
  .bottom_input {
    border: 3px solid #000;
  }
  input{
    border: 0;
  }
  input:focus-visible{
    outline-width: 0px
  }
}

.top {
  padding: 5px 0px;
  background: #474747;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: right;
  height: 30px;
  width: 100%;
}

.chat_text {
  // height: 250px;
  // width: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
  padding-bottom: 10px;
  height: calc(100% - 50px);
  // justify-content: center;
}


.bottom_input input {
  width: calc(100% - 4px);
  height: 30px;
  border-color: #f6f6f6;
  border-left: 0px;
  border-bottom: 0px;
  border-right: 0px;
}


.dot {
  height: 20px;
  width: 35px;
  // background-color: #181818;
  // border-radius: 50%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  margin-right: 5px;
  padding: 2px 0px;
  font-size: 22px;
  // vertical-align: middle;
}

.chat_action {
  padding-left: 5px;
  width: 100%;
  // background-color: #777;
  // border-bottom: 1px solid #fff;
  // padding: 3px;
  color: #000;
  font-size: 13px;
}

.chat_title {
  font-weight: bold;
}

.chat_action_create {
  padding: 5px 0px;
  width: 100%;
  text-align: center;
  background-color: #d4d4d7;
  // border-bottom: 1px solid #fff;
  // padding: 3px;
  color: rgb(0, 0, 170);
  font-size: 13px;
  font-weight: bold;
}

#chat_table {
  border: 1px solid black;
  color: black;
}

#chat_table th {
  border: 1px solid black;
}

.tables {
  position: absolute;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -70px;
}

// #mytable{
//   z-index: 999999;
//   position: absolute;
//   left: 50%;
//   transform: translate(-50%, 0%);
//   // margin-left: auto;
//   // margin-right: auto;
// }


// #pointTable{
//   z-index: 999999;
//   position: absolute;
//   left: 30%;
//   transform: translate(-50%, 0%);
//   // margin-left: auto;
//   // margin-right: auto;
// }

.react-confirm-alert-overlay {
  z-index: 99999999 !important;
}


#mytable,
#mytable th,
#mytable td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  /*border-collapse: collapse; */
  border-radius: 5px;
}

#mytable {
  margin: 0px auto;
  background: teal;
}

#mytable tr:nth-child(1n) {
  // opacity: 0;
}

/* To center just the first column */
#mytable tr td:first-child {
  text-align: center;
}

#mytable tr:nth-child(2n) {
  background: #bbb;
}

#mytable tr:nth-child(2n - 1) {
  background: #fff;
}

#pointTable,
#pointTable th,
#mytable td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  /*border-collapse: collapse; */
  border-radius: 5px;
}

#pointTable {
  margin: 0px auto;
  background: teal;
}

#pointTable tr:nth-child(1n) {
  // opacity: 0;
}

/* To center just the first column */
#pointTable tr td:first-child {
  text-align: center;
}

#pointTable tr:nth-child(2n) {
  background: #bbb;
}

#pointTable tr:nth-child(2n - 1) {
  background: #fff;
}

.pottable {
  display: flex;
  align-items: center;
  position: absolute;
  left: 67vw;
  /* width: auto; */
  top: 30vh;
  justify-content: center;
  height: 28vh;
}

.wincoinval {
  position: absolute;
  top: 26px;
  left: 12px;
  color: white;
  font-weight: bold;
  background: black;
  font-size: 12px;
}

.center_pot {
  position: absolute;
  min-width: 6vw;
  height: 2.5vh;
  background: black;
  top: 18.3vh;
  left: 47vw;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  border: 1px solid #353535;
  border-radius: 8px;
}

.all_pots {
  position: absolute;
  height: 3vh;
  // background: black;
  top: 59.5vh;
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cccccc;
  flex-direction: row;
}

.all_pot_stack {
  background: #000;
  border: 1px solid #353535;
  border-radius: 8px;
  height: 2.5vh;
  min-width: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.player_chips_and_blind {}

div.ccccc {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: auto;
  // margin:0 47px 0 0;
  // padding:24px 22px 20px 27px;
  border: 1px solid transparent;
}

div.ccccc:last-child {
  margin: 0px;
}

div.ccccc:hover {
  border: 1px solid #878787;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

div.ccccc.unselected {
  opacity: 0.6;
  filter: alpha(opacity=60);
}

div.ccccc.selected {
  border: 1px solid #32a24e;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.card-container {
  min-width: 12vw;
  max-width: 300px;
  height: 8vh;
  max-height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.card-container div {
  max-height: 100%;
  display: table-cell;
}

.arrange_div_card {
  position: absolute;
  display: flex !important;
  flex-direction: row;
  min-width: 12vw;
  max-width: 300px;
  height: 8vh;
  max-height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // margin-left: -20px;
  // border: 1px solid red;
}

.arrange_div_card div {
  display: flex;
  flex-direction: row;
  width: max-content;
  justify-content: center;
}

.arrange_div_card #card_border:first-child {
  border-top: 5px solid cyan;
  // padding: 2px !important;
  height: max-content;
}

.arrange_div_card #card_border:nth-child(2) {
  border-top: 5px solid mediumpurple;
  // padding: 2px !important;
  height: max-content;
}

.arrange_div_card #card_border:nth-child(3) {
  border-top: 5px solid red;
  // padding: 2px !important;
  height: max-content;
}

.arrange_div_card div .al_card_top {
  position: absolute;
  top: -10px;
}

.arrange_div_card div .al_card_top img {
  visibility: visible;
  height: 10px;
}

.arrange_div_card div img {
  height: 8.9vh;
  visibility: hidden;
}

.card_arr div {
  // border-top: 1px solid white;
  padding-top: 2px;
}

// .card_arr div:nth-child(1){
// border-left: 1px solid white;
// padding-top: 2px;
// }

// .card_arr div:nth-child(2){
//   border-left: 1px solid white;
//   // padding-top: 2px;
// }
// .card_arr div:nth-child(4){
//   border-left: 1px solid white;
//   // padding-top: 2px;
// }

// .card_arr div:nth-child(6){
//   border-right: 1px solid white;
//   // padding-top: 2px;
// }

.card-container div img {
  height: 8.5vh;
  padding-top: 6px;
}

.my_cards div img:hover {
  cursor: pointer;
  // box-shadow: rgba(0, 0, 0, 0.22) 0px 19px 43px;
  // transform: translate3d(0px, -2px, 0px);
}

.player-container {
  position: absolute;
  top: 6vh;
  /* border: 2px solid gray; */
  height: 8vh;
  width: 17vw;
  max-width: 200px;
  display: flex;
  flex-direction: row;
  left: 1.5vw;
  align-items: center;
}

.pl-ava-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8vh;
  height: 8vh;
  z-index: 100;
  // background-color: #343434;
  // border-right: 2px solid gray;
}

.pl-ava {
  border-radius: 5vw;
  border: 2px solid gray;
  width: 6.6vh;
  height: 6.6vh;
}

.pl-ava-no-timer {
  border-radius: 5vw;
  border: 2px solid gray;
  width: 8vh;
  height: 8vh;
}

.pl-name-chips {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  font-size: 1.7vh;
  text-align: center;
  z-index: 99;
  width: 100%;
  background-color: #343434;
  border: 2px solid gray;
  // border-radius: 16px;
  height: 76%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: -10px;
}


.player-name {
  color: #fff;
  font-weight: bold;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid gray;
  background-color: #343434;
  width: 100%;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.chips {
  color: #fff;
  font-weight: bold;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.show_status {
  position: absolute;
  display: flex;
  color: white;
  background-color: #343434;
  height: 76%;
  width: calc(17vw - 8.5vh);
  max-width: calc(200px - 8.1vh);
  max-height: 7.9vh;
  z-index: 999999;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 20px;
}

#card_arrng_line {
  // display: flex;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  height: 3px;
  // width: 27vw;
  // max-width: 285px;
  // display: flex;
  // justify-content: center;
  width: 27vw;
  max-width: 285px;
  // height: 8vh;
  // max-height: 8vh;
  display: flex;
  // flex-direction: row;
  justify-content: center;
}

#c_0 div,
#c_1 div,
#c_2 div,
#c_3 div,
#c_4 div,
#c_5 div,
#c_6 div,
#c_7 div {
  width: 47.281px;
  // max-height: 100%;
  // display: table-cell;
}

#c_0 {
  display: flex;
  border-left: 1px solid #fff;
}

#c_1 {
  display: flex;
  border-left: 1px solid #fff;
}

#c_2 {
  display: flex;
  border-left: 1px solid #fff;
}

#c_3 {
  display: flex;
  border-left: 1px solid #fff;
}

#c_4 {
  display: flex;
  border-left: 1px solid #fff;
}

#c_5 {
  display: flex;
  border-left: 1px solid #fff;
}

#c_6 {
  display: flex;
  border-left: 1px solid #fff;
}

// .tablecus tbody tr td {
//   border: 1px solid white
// }

.flip-2-ver-left-1 {
  -webkit-animation: flip-2-ver-left-1 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: flip-2-ver-left-1 10s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-23 0:42:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-2-ver-left-1
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-left-1 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
    transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  100% {
    -webkit-transform: translateX(-100%) rotateY(180deg);
    transform: translateX(-100%) rotateY(180deg);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

@keyframes flip-2-ver-left-1 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
    transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }

  100% {
    -webkit-transform: translateX(-100%) rotateY(180deg);
    transform: translateX(-100%) rotateY(180deg);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
  }
}

#custom_card_block {
  display: flex;
  justify-content: flex-end;
  // font-size: 2vh;
}

#custom_mini_card {
  width: fit-content !important;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: 1.2vh !important;
  margin-right: 1px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-weight: 700;
  border-radius: 2px;
  align-items: center !important;
  justify-content: center !important;
  background-image: none !important;
  min-width: 1px !important;
}

#custom_mini_card span:nth-child(2) {
  margin-top: -2px
}

.b_hand tr td {
  font-size: 2vh;
  text-align: center;
}

.b_hand tr td:nth-child(2) {
  padding-left: 5px;
  padding-right: 5px;
}

.b_hand tr td:nth-child(3) {
  width: 7vw;
}

.b_hand tr td:nth-child(4) {
  min-width: 6vw;
}

.card_change_info {
  display: none;
  // position: absolute;
  // top: 3vh;
  margin-left: 0.5vw;
  width: max-content;
  font-size: 1vw;
  text-align: center;
  cursor: pointer;
}

.card_change_info img {
  height: 2vh;
  margin-top: 2px;
}

.card_change_info span {
  margin-left: 0.1vw;
}

// #td_dis div {
//   padding: 0.1vw;
//   text-align: center;
//   vertical-align: middle;
//   font-size: 2vh;
// }

// #td_dis div:nth-child(1) {
//   width: 40px;
//   background-image: url(../poker-table-icon.png);
//   background-repeat: no-repeat, repeat;
//   background-size: 100%;
// }

// #td_dis div:nth-child(2) {
//   width: 20px;
// }

// #td_dis div:nth-child(3) {
//   width: 8vw;
// }

// .hand_name {

// }

// @media screen and (max-width: 655px) {

//   #td_dis div:nth-child(3) {
//     width: 8.5vw;
//   }
// }

// #td_dis div:nth-child(4) {
//   width: 6vw;
//   background-image: null;
// }

#td_dis #custom_board {
  width: 2vw;
  background-image: url(../poker-table-icon.png);
  background-repeat: no-repeat, repeat;
  background-size: 100% 90%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  #best_hand_tab {
    bottom: 45px !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 800px) {
  #best_hand_tab {
    bottom: 50px !important;
  }
}

.ui-draggable,
.ui-droppable {
  background-position: top;
}

.sortable {
  list-style-type: none;
  margin: 0;
  padding: 0;
  // width: 450px; 
}

.sortable div {
  padding: 1px;
  float: left;
  text-align: center;
}

.toggle .btn-primary {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}

.toggle .btn {
  font-weight: bold !important;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.toggle-on.btn {
  padding-right: 24px;
}

.toggle-group .btn-default:active,
.toggle-group .btn-default.active,
.toggle-group .open>.toggle-group .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open>.dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  box-shadow: inset 0 3px 5px #adadad;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
  color: #fff !important;
  font-weight: bold !important;
  background-color: #3071a9;
  border-color: #285e8e;
}

.btn:hover,
.btn:focus {
  color: #333;
  text-decoration: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.toggle.btn {
  display: inline-block;
  // padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.toggle-handle.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
  border-radius: 20px;
}

.toggle.ios .toggle-handle {
  border-radius: 20px;
}

.toggle-handle {
  position: relative;
  margin: 0 auto;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  height: 100%;
  width: 0px;
  border-width: 0 1px !important;
}

.toggle-on.btn-xs {
  padding-right: 12px;
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.toggle-off.btn-xs {
  padding-left: 12px;
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-xs,
.btn-group-xs>.btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.modal-content .end{
  min-height: 200px;
}