
#menu_item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

#home_header #menu_item #menu a{
    margin: 0;
    height: auto;
    padding: 10px 25px;
    min-width: 0;
    border-color: #ba070c;
    color: #fff;
    font-weight: bold;
}

#home_header #menu_item #menu a:hover {
    color:#ba070c;
    background-color: transparent;
}

#menu_item #menu {
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
}

header {
    text-align: center;
    font-size: 35px;

    h3 {
        display: inline-block;
        font-family: Montserrat, Montserrat;
        font-weight: 100;
        font-size: 35px;
        margin: 0px;
        color: #aa7f40;
    }

    h4 {
        display: inline-block;
        font-family: Paytone One, Montserrat;
        margin: 0px;
        color: #aa7f40;
        font-weight: 100;
        font-size: 37px;
    }
}

// #home_header {
//     background: rgb(186, 7, 12);
//     background: linear-gradient(99deg, rgba(186, 7, 12, 1) 0%, rgba(1, 1, 1, 1) 75%, rgba(1, 1, 1, 1) 100%);
//     height: 10vh;
// }

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: #1ac6ff;
}

.navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 16px;
}

a.nav-link {
    margin-left: 35px;
}

.start {
    text-align: left;
    background-image: url(../1668220.jpg);
    width: 100%;
    height: 700px;
    background-size: cover;
    background-position: 50% 80%;
}

.start a {
    // position: absolute;
    background: linear-gradient(90deg,
            rgb(168, 158, 9) 0%,
            rgb(255, 247, 17) 100%);
    padding: 14px 80px 14px 80px;
    bottom: 0%;
    left: 41%;
    text-decoration: none;
    border-radius: 50px;
    color: #000;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
}

.start a:hover {
    background: #000;
    color: rgb(255, 247, 17);
}

#home1 #home h1 .highlight {
    color: #ba070c;
    font-weight: bolder;
    font-size: 60px;
    cursor: pointer;
    animation: animate 2s linear infinite;
}
@keyframes animate{
    0%{
      opacity: 0.3;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 0.3;
    }
  }
#home1 #home h1 .highlight:hover{

    background: linear-gradient(
99deg
, #ba070c 25%, #fff 85%, #fff 100%);
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
}
div#home {
    padding-left: 4vw;
}

#home1 #home h1 {
    color: white;
    font-size: 50px;
    font-family: "Montserrat";
    font-weight: bold;
    padding-top: 35vh;
    padding-left: 20vh;
    margin: 0;
    line-height: 40px;
}

#home1 #home h5 {
    color: white;
    font-size: 30px;
    padding-left: 20vh;
    margin-top: 5vh;
    line-height: 40px;
}
.anchor {
    position: absolute;
    left: 5%;
    top: 15%;
}
.anchor a {
    font-size: 18px;
    font-weight: bold;
   color: #fff;
    text-decoration: none;
    padding-left: 10vh;
}
.anchor a:hover{
    background: #ddd6f3;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #faaca8, #ddd6f3);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #faaca8, #ddd6f3); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */    
-webkit-text-fill-color: transparent;
-webkit-background-clip: text;
}
.details {
    position: relative;
    background: #fff;
    background-position: center;
    width: 100%;
    padding-bottom: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
}

.detail {
    background: #3a6186;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #89253e, #3a6186);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #89253e, #3a6186);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    padding-top: 50px;
    padding-bottom: 50px;
}
.detail .pictures{
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
}
.detail h2 {
    color: #fff;
    padding-bottom: 20px;
}

.detail h4 {
    color: #fff;
    padding-top: 20px;
  
}

.detail p {
    color: #fff;
}

.mobile{
    cursor: pointer;
    object-fit: cover; 
    height: 65vh;
}
.mobile3 {
    cursor: pointer;
    object-fit: cover;
    height: 65vh;
}
.mobile1{
    height: 65vh;
}
.footer {
    justify-content: space-between;
    align-items: center;
    background-color: #000;
}

.footer p {
    color: #fff;
    padding-top: 10px;
}

.start {
    padding-bottom: 0;
}

.icon {
    width: 100px;
    height: 100px;

}

.details h3 {
    color: #000;
    padding-top: 50px;
    padding-bottom: 10px;
    font-size: 35px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.details h4 {
    color: #000;
    font-size: 30px;
    padding-top: 10px;
    margin-bottom: 10px;
}

.details p {
    color: #000;
    padding-bottom: 10px;
    font-size: 20px;
   text-align: center;
   margin-top: 10px;
  padding-left: 100px;
  padding-right: 100px;
}
.bla{
    display: flex;
    justify-content: space-around;
}