// /* CSS Document */
// html {
// 	position: fixed;
// 	overflow: hidden;
// 	-webkit-font-smoothing: antialiased;
// }
// body {
// 	background-color: #000;
// }

// html.standalone.portrait {
// 	padding-top: 36px;
// }

// ::-webkit-scrollbar {
// 	display: none;
// }
// /* container widths */

// .hidden {
// 	display: none !important;
// }
// .container.width-small {
// 	width: 360px;
// }

// /* end container widths */



// .carousel-control-prev, .carousel-control-next {
// 	font-size: 4rem;
// }

// @media (min-width: 768px) and (max-width: 991px) {
// 	/* Show 3rd slide on md  if col-md-4*/
// 		  .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
// 		  position: absolute;
// 		  top: 0;
// 		  right: -33.3333%;  /*change this with javascript in the future*/
// 		  z-index: -1;
// 		  display: block;
// 		  visibility: visible;
// 	  }
  
//   }
  
//   @media (min-width: 576px) and (max-width: 768px) {
// 	/* Show 2 slide on md  if col-md-4*/
// 		  .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
// 		  position: absolute;
// 		  top: 0;
// 		  right: -50%;  /*change this with javascript in the future*/
// 		  z-index: -1;
// 		  display: block;
// 		  visibility: visible;
// 	  }
  
//   }
//   @media (min-width: 576px) {
// 	.carousel-item {
// 	margin-right: 0;
//   }
  
// 	  /* show 2 items */
// 	  .carousel-inner .active + .carousel-item {
// 		  display: block;
// 	  }
	  
// 	  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
// 	  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
// 		  transition: none;
// 	  }
  
// 	  .carousel-inner .carousel-item-next {
// 		position: relative;
// 		transform: translate3d(0, 0, 0);
// 	  }
	  
// 	  /* left or forward direction */
// 	  .active.carousel-item-left + .carousel-item-next.carousel-item-left,
// 	  .carousel-item-next.carousel-item-left + .carousel-item,
// 	  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
// 		  position: relative;
// 		  transform: translate3d(-100%, 0, 0);
// 		  visibility: visible;
// 	  } 
	  
// 	  /* farthest right hidden item must be abso position for animations */
// 	  .carousel-inner .carousel-item-prev.carousel-item-right {
// 		  position: absolute;
// 		  top: 0;
// 		  left: 0;
// 		  z-index: -1;
// 		  display: block;
// 		  visibility: visible;
// 	  }
	  
// 	  /* right or prev direction */
// 	  .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
// 	  .carousel-item-prev.carousel-item-right + .carousel-item,
// 	  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
// 		  position: relative;
// 		  transform: translate3d(100%, 0, 0);
// 		  visibility: visible;
// 		  display: block;
// 		  visibility: visible;
// 	  }
  
//   }
  
//   /*MD*/
//   @media (min-width: 768px) {
  
// 	  /* show 3rd of 3 item slide */
// 	.carousel-inner .active + .carousel-item + .carousel-item {
// 		  display: block;
// 	  }
   
// 	  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
// 		  transition: none;
// 	  }
	
	  
// 	  .carousel-inner .carousel-item-next {
// 		position: relative;
// 		transform: translate3d(0, 0, 0);
// 	  }
	  
	  
// 	  /* left or forward direction */
// 	  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
// 		  position: relative;
// 		  transform: translate3d(-100%, 0, 0);
// 		  visibility: visible;
// 	  }
	  
// 	  /* right or prev direction */
// 	  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
// 		  position: relative;
// 		  transform: translate3d(100%, 0, 0);
// 		  visibility: visible;
// 		  display: block;
// 		  visibility: visible;
// 	  }
  
//   }
  
  
//   /*LG 6th*/
//   @media (min-width: 991px) {
  
// 	  /* show 4th item */
// 	  .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
// 		  display: block;
// 	  }
	  
// 	  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
// 		  transition: none;
// 	  }
	  
  
  
	  
// 	  /* right or prev direction //t - previous slide direction last item animation fix */
// 	  .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
// 		  position: relative;
// 		  transform: translate3d(100%, 0, 0);
// 		  visibility: visible;
// 		  display: block;
// 		  visibility: visible;
// 	  }
  
//   }
  
//   /*LG 6th*/
  
//   @media (min-width: 991px) {
  
	
// 	  .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
// 	.carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
// 		transition: none;
// 	  }
  
	  
	
// 	/*show 7th slide for animation when its a 6 slides carousel */
// 	.carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item {
// 		  position: absolute;
// 		  top: 0;
// 		  right: -33.333333333%;
// 		  z-index: -1;
// 		  display: block;
// 		  visibility: visible;
// 	}
	
// 		/* forward direction > */
// 	  .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item,
// 	.carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
// 		  position: relative;
// 		  transform: translate3d(-100%, 0, 0);
// 		  visibility: visible;
// 	  }
	
	
  
//   }




// /* form */

// .form {
// 	padding: 15px;
//     background: #222;
//     border: 1px solid #333;
// 	border-radius: 5px;
// 	max-width: 420px;
// }

// /* Chrome, Safari, Edge, Opera */
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type=number] {
//   -moz-appearance: textfield;
// }

// /* alert */

// .alert {
// 	background-color: rgba(0,0,0,0.7);
//     width: 100vw;
//     height: 100vh;
//     z-index: 999;
//     padding: 0;
//     border: none;
//     border-radius: 0;
//     position: fixed;
// 	color: #fff;
// 	display: none;
// }

// .alert .frame #lblTitle {
// 	font-size: 2rem;
// }

// .alert .frame .btn {
// 	min-width: 130px;
// }

// .alert .frame {
// 	// // background-image: url(../assets/window-thin.png);
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     background-position-x: center;
//     width: 100vw;
//     height: 220px;
//     margin: calc(50vh - 120px) auto;
//     position: relative;
//     padding-top: 18px;
// }

// .alert .frame .container {
//     max-width: 480px;
//     margin: 0 auto;
//     display: block;
//     height: 100%;
// 	text-align: center;
// 	padding:0px;
// }

// .alert .frame .container .footer {
//     text-align: center;
// 	max-width: 450px;
//     width: 100%;
//     position: absolute;
//     bottom: 20px;
// }

// /* end alert */


// label {
//     font-size: 11px;
//     margin: 0;
// }



.fixed-body .contents {
	padding-top: 10px;
}


// /* form controls */

// .contents.create .hidden-create {
// 	display: none !important;
// }

/* top menu icons */
.top-menu {
    margin: 0px 0px -8px 0px;
    // // background-image: url(../assets/top-bar-basic.png);
    background-size: 100% 133%;
    background-repeat: no-repeat;
    padding-bottom: 1px;
	display: inline-block;
	width: 100%;
}

.top-menu .btnItem {
    float: left;
    width: 96px;
    height: 70px;
    text-align: center;
    position: relative;
    // // background-image: url(../assets/home-button-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-right: -1px;
}

.top-menu .btnItem .btnItemBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(../home-button-bg-off.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.top-menu .btnItem.active .btnItemBackground {
	background-image: url(../home-button-bg-on.png);
}

.top-menu .btnItem .btnItemText {
    bottom: 10px;
    position: absolute;
    font-size: 0.7em;
    width: 100%;
    text-align: center;
	transition: 0.5s ease;

}

.mobile .top-menu .btnItem .btnItemText {
	font-size: 0.6em;
}

.top-menu .btnItem.active .btnItemText {
	display: block;
    bottom: 4px;
	color: lightgray;
	font-size: 0.8em;
	transition: 0.5s ease;
	text-shadow: 2px 2px 4px #000000;
}

.mobile .top-menu .btnItem.active .btnItemText {
	font-size: 0.7em;
}

.top-menu .btnItem .btnItemIcon {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 96px;
    height: 70px;
    transition: 0.5s ease;
    margin: 0 auto;
    margin-top: -10px
}

.mobile .top-menu .btnItem .btnItemIcon {
	width: 82px;
    height: 60px;
}

.top-menu .btnItem.active .btnItemIcon {
    background-position-y: -5px;
}

.mobile .top-menu .btnItem.active .btnItemIcon {
    background-position-y: -4px;
}

.btnItem.icon-back .btnItemIcon {
	background-image: url("../back-icon-on.png");
}
.top-menu .btnItem.active.icon-back .btnItemIcon {
	background-image: url("../back-icon-on.png");
}
.top-menu .btnItem.icon-casino .btnItemIcon {
	background-image: url("../casino-icon-on.png");
}

.top-menu .btnItem.icon-private .btnItemIcon {
	background-image: url("../private-icon-on.png");
}

.top-menu .btnItem.icon-clubs .btnItemIcon {
	background-image: url("../clubs-icon-on.png");
}

.top-menu .btnItem.icon-profile .btnItemIcon{
	// background-image: url(../assets/profile-icon.png);
}
.top-menu .btnItem.active.icon-profile .btnItemIcon{
	// background-image: url(../assets/profile-icon-on.png);
}

.top-menu .btnItem.icon-statistics .btnItemIcon{
	// background-image: url(../assets/statistics-icon.png);
}
.top-menu .btnItem.active.icon-statistics .btnItemIcon{
	// background-image: url(../assets/statistics-icon-on.png);
}

.top-menu .btnItem.icon-history .btnItemIcon{
	// background-image: url(../assets/history-icon.png);
}
.top-menu .btnItem.active.icon-history .btnItemIcon{
	// background-image: url(../assets/history-icon-on.png);
}

.top-menu .btnItem.icon-casino .btnItemIcon{
	// background-image: url(../assets/casino-icon.png);
}
.top-menu .btnItem.active.icon-casino .btnItemIcon{
	// background-image: url(../assets/casino-icon-on.png);
}

.top-menu .btnItem.icon-prizepool .btnItemIcon{
	// background-image: url(../assets/casino-icon.png);
}
.top-menu .btnItem.active.icon-prizepool .btnItemIcon{
	// background-image: url(../assets/casino-icon-on.png);
}

.top-menu .btnItem.icon-cashgames .btnItemIcon{
	// background-image: url(../assets/casino-icon.png);
}
.top-menu .btnItem.active.icon-cashgames .btnItemIcon{
	// background-image: url(../assets/casino-icon-on.png);
}

.top-menu .btnItem.icon-leagues .btnItemIcon{
	// background-image: url(../assets/leagues-icon.png);
}
.top-menu .btnItem.active.icon-leagues .btnItemIcon{
	// background-image: url(../assets/leagues-icon-on.png);
}

.top-menu .btnItem.icon-tournament .btnItemIcon{
	// background-image: url(../assets/leagues-icon.png);
}
.top-menu .btnItem.active.icon-tournament .btnItemIcon{
	// background-image: url(../assets/leagues-icon-on.png);
}

.top-menu .btnItem.icon-sitngo .btnItemIcon{
	// background-image: url(../assets/clubs-icon.png);
}
.top-menu .btnItem.active.icon-sitngo .btnItemIcon{
	// background-image: url(../assets/clubs-icon-on.png);
}

.top-menu .btnItem.icon-clubs .btnItemIcon{
	// background-image: url(../assets/clubs-icon.png);
}
.top-menu .btnItem.active.icon-clubs .btnItemIcon{
	// background-image: url(../assets/clubs-icon-on.png);
}

.top-menu .btnItem.icon-games .btnItemIcon{
	// background-image: url(../assets/games-icon.png);
}
.top-menu .btnItem.active.icon-games .btnItemIcon{
	// background-image: url(../assets/games-icon-on.png);
}

.top-menu .btnItem.icon-players .btnItemIcon{
	// background-image: url(../assets/clubs-icon.png);
}
.top-menu .btnItem.active.icon-players .btnItemIcon{
	// background-image: url(../assets/clubs-icon-on.png);
}

.top-menu .btnItem.icon-battle .btnItemIcon{
	// background-image: url(../assets/battle-icon.png);
}
.top-menu .btnItem.active.icon-battle .btnItemIcon {
	// background-image: url(../assets/battle-icon-on.png);
}

.top-menu .btnItem.icon-logout .btnItemIcon{
	// background-image: url(../assets/logout-icon.png);
}
.top-menu .btnItem.active.icon-logout .btnItemIcon{
	// background-image: url(../assets/logout-icon-on.png);
}

.top-menu .btnItem.icon-privategames .btnItemIcon{
	// background-image: url(../assets/private-icon.png);
}
.top-menu .btnItem.active.icon-privategames .btnItemIcon{
	// background-image: url(../assets/private-icon-on.png);
}

.top-menu .btnItem.icon-cashier .btnItemIcon{
	// background-image: url(../assets/cashier-icon.png);
}
.top-menu .btnItem.active.icon-cashier .btnItemIcon{
	// background-image: url(../assets/cashier-icon-on.png);
}

.top-menu .btnItem.icon-tournaments .btnItemIcon{
	// background-image: url("../assets/tournaments-icon.png");
}

.top-menu .btnItem.active.icon-tournaments .btnItemIcon{
	// background-image: url("../assets/tournaments-icon-on.png");
}

.top-menu .btnItem.icon-levels .btnItemIcon{
	// background-image: url("../assets/levels-icon.png");
}

.top-menu .btnItem.active.icon-levels .btnItemIcon{
	// background-image: url("../assets/levels-icon-on.png");
}


.mobile .top-menu .btnItem {
	width: 82px;
	height: 60px;
}
.mobile.portrait .top-menu .btnItem {
	width: 25vw;
	margin-right: 0px;
}

/* END TOP-MENU */


// /* privateGame */

#privateGame.cashgame.edit a.btnTab, #privateGame.tournament.edit a.btnTab {
	display: none;
}

#privateGame.cashgame.create h2, #privateGame.tournament.create h2 {
	display: none;
}

#privateGame.cashgame.edit h2, #privateGame.tournament.edit h2 {
	display: block;
}


/* tab-menu */


.tab-menu {
	list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    -webkit-box-pack: center;
    justify-content: center;
	font-size: 14px;
	// background-image: url(../assets/top-bar-basic.png);
    background-size: 100% 138%;
    background-repeat: no-repeat;
	padding-top: 2px;
}

.tab-menu.no-bg {
	// background-image: none;
	margin-bottom: 14px;
}

.tab-menu .tab-row {
	display: inline-block;
}

.tab-menu .btnTab { 
	display: inline-block;
	width: 143px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
	position: relative;
	background-image: url("../tab-off.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.tab-menu.no-bg .btnTab { 
	width: 50%;
}

.tab-menu .btnTab.active {
	background-image: url("../tab-on.png");
	text-shadow: 2px 2px 4px #000000;
    color: lightgray !important;
}

.mobile.portrait .tab-menu .btnTab {
	width: 50vw;
}

.mobile.portrait .tab-menu.no-bg .btnTab {
	width: 50%;
}

/* tab-menu */



// /* PAGE */

.page {
	position: fixed;
	width: 100%;
    height: 100%;
	display: none;
}

.page.active {
	display: flex;
    flex-flow: column;
}

// /* END PAGE */


// /* POPUP */

// .popup {
// 	position: fixed;
// 	width: 100%;
//     height: 100%;
// 	z-index: 9;
// 	display: none;
// }

// .popup.active {
// 	display: block;	
// 	// background-image: url(../assets/wpas-texture.jpg?t=1);
//     background-size: cover;
//     background-position: center;
// }

// .popup .contents {
//     margin-top: -18px;
//     height: 100%;
// }

// /* END POPUP */

// /* PROFILE */

.fixed-body{
 overflow: unset !important;
}
.fixed-body table {
    width: 100vw;
}

.fixed-body table tr {
	display: flex;
	padding: 8px;
	border-bottom: 1px solid #121212;
    background-color: rgba(30,30,30,1);
    background: rgb(32,32,32);
	background: linear-gradient(180deg, rgba(32,32,32,1) 0%, rgba(36,36,36,1) 100%);
    border-top: 1px solid #3a3a3a;
}

.fixed-body table tr:hover {
	background: rgb(79,58,2);
	background: linear-gradient(260deg, rgba(79,58,2,1) 0%, rgba(57,31,0,1) 100%);
	border-top: 1px solid rgba(89,65,0,1);
	border-bottom: 1px solid rgba(46,25,0,1);
}

.fixed-body table tr th, .fixed-body table tr td{
	white-space: nowrap;
    flex: 1;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    text-align: center;
}

.fixed-body table tr th:first-of-type, .fixed-body table tr td:first-of-type {
    text-align: left;
}

.fixed-body table tr th {
	color: lightgray;
    width: 100%;
    display: inline-block;
}
    

// #club .container {
// 	width: 98%;
// }

// #playerProfile #avatar {
// 	display: inline-block;
//     width: 156px;
//     height: 156px;
//     background-size: contain;
//     border-radius: 50%;
// 	border: 1px solid;
// 	margin-left: 10px;
// }


// #avatarOptions .avatar {
// 	display: inline-block;
//     width: 156px;
//     height: 156px;
//     background-size: contain;
//     border-radius: 50%;
// 	border: 1px solid;
// }

// #club .btn {
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// 	display: inline-block;
// 	font-weight: 700;
// 	font-style: normal;
// 	font-family: "Montserrat", sans-serif;
// 	text-transform: uppercase;
// 	-webkit-transition: all 0.3s ease;
// 	transition: all 0.3s ease;
// 	padding: 13px 42px;
// 	color: #212529;
// 	text-align: center;
// 	vertical-align: middle;
// 	-webkit-user-select: none;
// 	-moz-user-select: none;
// 	-ms-user-select: none;
// 	user-select: none;
// 	background-color: transparent;
// 	border: 1px solid transparent;
// 	padding: .375rem .75rem;
// 	font-size: 1rem;
// 	-webkit-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
// 	transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
// 	transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
// 	transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
// 	text-decoration: none;
//     box-shadow: none;
//     line-height: 2.2em;
//     border-radius: 2px;
// 	font-size: 11px;
// }

// #club form .btn {
// 	padding: 8px 8px 8px 8px;
// 	min-width: 108px;
// }

// #club .modal-open .modal, #club .tab-pane.active {
// 	opacity: 1;
// }



#club .btn-info, #club .btn-approved {
	color:#fff;
	font-weight:700; 
	background-color: #ba070c;
	// background-image: url("../button-yellow.png");
}

// #club .btn-grey, #club .btn-pending, #club .btn-unapproved  {
// 	// background-image: url("../assets/button-grey.png");
// }

// #club .btn-red, #club .btn-banned  {
// 	// background-image: url("../assets/button-red.png");
// 	color: #fff;
// }

// #club .btn-green, #club .btn-admin  {
// 	// background-image: url("../assets/button-green.png");
// }

.btnPlayNow{
	background: #ba070c;
	margin: 8px;
	transition: .6s;
}
.btnPlayNow:hover{
transform: scale(1.2);
}
.diff:hover{
	transform: scale(1.1);
	background: #ba070c;
}
#club .btn-block {
	display: block;
}

#club .btn-xs {
	padding: 5px 13px;
	font-size: 0.7;
    line-height: 2.2em;
	border-radius: 2px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

#club .btn-block {
	padding-left: 0;
	padding-right: 0;
}

#club .btn-large {
	width: 160px;
}

#club .btn-large.btnAction {
	width: auto;	
	min-width: 120px;
}

#club .btn-primary-inverse {
    color: #fff;
	
	/*
    background-color: #38a9ff;
    border-color: transparent;
	*/
	
	// background-image: url("../assets/button-blue.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

// #club .btn-success {
//     color: #333;
// 	// background-image: url("../assets/button-green3.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }

// #club .btn-success.lockedIn{
//     // background-image: url("../assets/button-grey.png");
// }

// #club .btn-success.signedUp{
//     // background-image: url("../assets/button-red.png");
// 	color: #fff;
// }

// #club .nav-tabs .nav-link.active, #club .nav-tabs .nav-item.show .nav-link {
//     color: #495057;
//     background-color: #fff;
//     border-color: #dee2e6 #dee2e6 #fff;
// }


#club .btnPlayNow, #club .btnEditGame, #club .btnViewTourny, #club .knockedOut, #club table tr td a {
	white-space: nowrap;
	padding: 6px 8px 6px 8px;
	text-decoration: none;
	box-shadow: none;
    float: right;
    max-width: 140px;
    margin:0;
	margin-right: 8px;
}

#club .btnEditGame {
    display: none;
}

#club .btnEditGame.enabled, #club .btnPlayNow, #club .scrollTable a {
	display: block;
	float: right;
}

// #club .btnShare {
// 	// background-image: url("../assets/button-blue.png");	
// 	color: #fff;
// }


// #club .btn-extra-small {
//     width: 49%;
//     float: left;
//     vertical-align: top;
//     margin: auto;
// }


// #club {
// 	background-color: #1e2024;
// 	color: #9a9da2;
// 	font-family: "Montserrat", sans-serif;
// 	// background-image: url(../assets/wpas-texture.jpg?t=1);
//     background-size: cover;
//     background-position: center;
// }

// .ep-plugin #club {
// 	overflow-y: scroll;
//     position: relative;
// }

// #tournament {
// 	display: none;
//     background-color: #1e2024;
//     width: 100vw;
// 	height: 100vh;
// 	// background-image: url(../assets/wpas-texture.jpg?t=1);
//     background-size: cover;
//     background-position: center;
// }

// #tournament.active {
// 	display: block;
// }

#tournament .fixed-body .active {
	display: block;
}


// #tournament .btnActive {
// 	color: #fff;
// }

// #tournament .btnActive::before{
// 	height: 4px !important;
//     opacity: 1 !important;
// }

// #tournament .tournamentName {
// 	color: lightgray;
// 	font-size: 34px;
// 	text-shadow: 2px 2px 4px #000000;
// }

// #tournament .tournament-actions {
// 	display: inline-block;
//     position: absolute;
//     right: 10px;
//     margin-top: -50px;
// }
// #tournament .details, #tournament .players, #tournament .prize-pool, #tournament .levels {
// 	display: none;
// 	/*border-bottom: 1px solid #35383f;*/
// }

// #tournament .ticket {
//     padding: 8px 8px;
// }

// #tournament .gameInfo {
// 	display: flex;
//     border-bottom: 1px solid #35383f;
//     border-top: 1px solid #35383f;
// }

// #tournament .gameDetails {
// 	display: flex;
// }

// #tournament .ticket .ticketData .ticketInfo {
// 	display: inline-block;
// }

// #tournament .ticket .ticketData .gameInfo .ticketInfo {
// 	-webkit-box-flex: 1;
//     flex-grow: 1;
//     position: relative;
//     padding: 10px 10px 10px 10px;
// }

// #tournament .ticket .ticketData .gameDetails .ticketInfo {
// 	-webkit-box-flex: 1;
//     flex-grow: 1;
//     position: relative;
//     padding: 10px 10px 10px 10px;
// }

#tournament .fixed-body .value {
	color: lightgray;
	font-size: 1em;
}

#tournament .fixed-body .title {
	color: lightgray;
	font-size: 2em;
}


// #tournament .name {
// 	color: gray;
// }

// #tournament .ticket .value {
// 	font-size: 18px;
// }

// #tournament .ticket .name {
// 	font-size: 12px;
// }

// #tournament .footer {
//     position: absolute;
// 	bottom: 20px;
// 	/*width: 100vw;*/
// 	left: 50%;
// 	transform: translate(-50%);
// }

// /*
// #tournament .footer .footerContents {
//     position: relative;
//     left: calc(50vw - 120px);
//     width: calc(50vw + 120px);
//     padding: 0px;
// }
// */

// #tournament .timer {
// 	/*border: 2px solid;
//     border-radius: 4px;*/
//     text-align: center;
//     width: 240px;
//     padding: 4px;
// 	/*background-color: #1e2024;*/
// 	// background-image: url("../assets/home-player-bg.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }

// #tournament .timer .timerTitle {
//     font-size: 10px;
// }

// #tournament .clock {
// 	display: inline-block;
// }

// #tournament .clock .value {
//     font-size: 30px;
//     text-align: center;
// }

// #tournament .clock.value {
// 	font-size: 30px;
//     bottom: 24px;
//     position: relative;
// }

// #tournament .clock .name {
// 	font-size: 10px;
//     text-align: center;
// }

// #tournament .footer-item {
// 	display: inline-block;
// }

// #tournament .footer-item.timerButton {
	
// }

// #tournament .footer .slider {
// 	overflow-y: hidden;
// 	max-height: 95px;
	
// 	transition-property: all;
// 	transition-duration: .5s;
// 	transition-timing-function: cubic-bezier(0,1,0.5,1);
// }

// #tournament .footer .slider.close {
// 	max-height: 0;
// }

// #club .tableFrame {
// 	height: 100%;
//     overflow: hidden;
// }
// #club .scrollTable {
// 	overflow-y: auto;
// 	max-height: calc(100vh - 181px);
// }

// #tournament #tnmTableBody, #tournament #tnmTableHeader {
// 	width: 100vw; 
// 	background-color: rgba(30,30,30,1);
// 	border-bottom: 1px solid #121212;
// }


// .mobile.portrait #club .scrollTable {
//     max-height: calc(100vh - 272px);
// }


// /* TOURNAMENT LOBBY */
// #tournament .timer {
//     display: block;
//     position: absolute;
//     margin: auto;
//     width: 242px;
//     height: 90px;
//     right: calc(50vw - 121px);
//     // background-image: url("../assets/home-player-bg.png");
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     transform: scale(0.8);
//     top: -10px;
// }

// .mobile.landscape #tournament .timer {
// 	top: -18px;
// }
// /* TOURNAMENT LOBBY END */


.fixed-header {
    width: 100%;
    background: #000;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    top: 0px;
    z-index: 4;
}

.fixed-body {
	overflow: scroll;
    flex-grow: 1;
    padding-bottom: 64px;
}

.fixed-footer {
	position: fixed;
    width: 100vw;
    z-index: 4;
    height: 80px;
	
    background-size: 100% 100%;
    background-repeat: no-repeat;
	bottom: 0;
}

.fixed-footer .gameLogoFrame {
	display: block;
    position: absolute;
    margin: auto;
    width: 242px;
    height: 90px;
    right: calc(50vw - 121px);
    background-image: url(../logo-basic-small-frame.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: scale(0.8);
    top: -10px;
}

.fixed-footer .gameLogoFrame .text {
	font-size: 1em;
}

.fixed-footer .gameLogoFrame .logo {
	// background-image: url("../assets/logo-basic-small-wpas.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	
	display: block;
    width: 242px;
    height: 90px;
}

#club.has-logo .fixed-footer .gameLogoFrame, #clubGames.has-logo .fixed-footer .gameLogoFrame {
	// background-image: none;
}

#club:not(.has-logo) .fixed-footer .gameLogoFrame .logo, #clubGames:not(.has-logo) .fixed-footer .gameLogoFrame .logo {
	// background-image: none;
}

#club:not(.has-logo) .fixed-footer .gameLogoFrame .text, #clubGames:not(.has-logo) .fixed-footer .gameLogoFrame .text {
    font-size: 3.5em;
    font-weight: bolder;
    text-align: center;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #333;
}

.mobile.landscape .fixed-footer {
	height: 60px;
}

.mobile.landscape .fixed-footer .gameLogoFrame {
	transform: scale(0.6);
	top: -20px;
}

.fixed-body .lobby .game, #tournament #tnmTableBody tr, #tournament #tnmTableHeader tr {
	border-top: 1px solid rgba(255, 255, 255, 0.05);
	border-bottom: none;
	padding: 8px;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.fixed-body .lobby .game:last-of-type {
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.fixed-body .lobby .game .title, #tournament #tnmTableHeader th {
	color: lightgray;
    width: 100%;
    display: flex;
    align-items: center;
}

.fixed-body .lobby .game .frame, #tournament #tnmTableBody tr, #tournament #tnmTableHeader tr {
	display: flex;
}
.fixed-body .lobby .game .frame div, #tournament #tnmTableBody tr td, #tournament #tnmTableHeader tr th {
	white-space: nowrap;
	flex: 1;
	overflow-x: unset;
	-ms-overflow-style: none;
	scrollbar-width: none;
	-webkit-overflow-scrolling: touch;
	text-align: center;
}

.fixed-body .lobby .game .frame div:first-of-type, #tournament #tnmTableBody tr td:first-of-type, #tournament #tnmTableHeader tr th:first-of-type {
	text-align: left;
}

.fixed-body .lobby .game .frame div.lateEntry:first-of-type {
	text-align: center;
}

.mobile.portrait .fixed-body .lobby .game .frame div.lateEntry:first-of-type {
	text-align: left;
}

.fixed-body .lobby .game .frame label {
	font-size: 10px;
	display: block;
	color: #555;
}

.fixed-body .lobby .game .frame span {
	color: #888;
}

.fixed-body .lobby .game .frame .action {
	max-width: 280px;    
	justify-content: flex-end;
	z-index: 1;
}

#lobby .privatecashgame .game .frame .action {
	margin-top: -22px;
	max-width: 200px;
}

.fixed-body .lobby .game, #tournament #tnmTableBody tr{
	border-bottom: 1px solid #121212;
	background: #000;
    // background-image: url(../demo.jpg);
  height: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
    border-top: 1px solid #3a3a3a;
	transition: ease-out 0.3s;
}

.fixed-body .lobby .game:hover, #tournament #tnmTableBody tr:hover{
// 	background: rgb(0,0,0);
// background-image: url(../1668214.jpg);
// background-size: cover;
// background-size: 400px 150px;
// background: linear-gradient(99deg, rgba(0,0,0,1) 6%, rgba(79,58,2,1) 43%, rgba(79,58,2,1) 47%, rgba(0,0,0,1) 100%);
}

.fixed-body .lobby .game:hover .title, .fixed-body table tr:hover {
	color: #fff;
	
}

// .fixed-body .lobby .game:hover {
// 	color: #b77f00;
// }

.fixed-body .lobby .game:hover .frame label {
	color: #b77f00;
}

.fixed-body .lobby .game:hover .frame span {
    color: #fff;
}


// #tournament #tnmTableBody tr.selected {
// 	background: rgb(79,58,2);
// 	background: linear-gradient(260deg, rgba(79,58,2,1) 0%, rgba(57,31,0,1) 100%);
// 	border-top: 1px solid rgba(89,65,0,1);
// 	border-bottom: 1px solid rgba(46,25,0,1);
//     color: #fff;
// }

.fixed-body .lobby.games .frameCol{
	display: flex;
}

.fixed-body .lobby.games .frameCol:last-of-type {
	flex: 0.2;
}

.fixed-body .lobby.games .frameRow{
	display: flex;
}

.fixed-body .lobby.games .frameRowCol{
	display: inline-block;
	flex: 1;
}

#lobby .game-types {
	margin: 0px 0px -8px 0px;
	// background-image: url(../assets/top-bar-basic.png);
    background-size: 100% 125%;
    background-repeat: no-repeat;
	padding-bottom: 1px;
}

.mobile.landscape #lobby .game-types, .mobile.landscape #privateGame .privateGame-window .header,  .mobile.landscape #tournament .tournament-menu {
	height: 65px;
}

.mobile.landscape #lobby .game-types .btnGameType {
    margin-right: -24px;
    transform: scale(0.8);
    top: -6px;
    left: -9px;
}

#lobby .game-types .btnGameType .btnGameTypeBackground {
	position: absolute;
	width: 100%;
	height: 100%;
	// background-image: url("../assets/home-button-bg-off.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

/*
#tournament .menu-item, #tournament .btnBack {
	background-size: 100% 100%;
	background-repeat: no-repeat;
    margin-right: 0;
}
*/

// #tournament .menu-item.btnActive .menu-item-Icon {
//     background-position-y: -8px;
// }



// /*
// #tournament a.menu-item {
// 	// background-image: url("../assets/home-button-bg-off.png");
// }

// #lobby .game-types .btnGameType.btnCashGames.btnActive .btnGameTypeBackground, #lobby .game-types .btnGameType.btnSitNGo.btnActive .btnGameTypeBackground, #lobby .game-types .btnGameType.btnTournaments.btnActive .btnGameTypeBackground, #lobby .game-types .btnGameType.btnPrivateGame.btnActive .btnGameTypeBackground, #tournament .menu-item .menu-item-Background, #tournament .btnBack .menu-item-Background{
// 	// background-image: url("../assets/home-button-bg-on.png");
// }
// */

// /*#lobby .game-types .btnGameType::before {
//     content: "";
//     display: block;
//     position: absolute;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     height: 0;
//     opacity: 0;
//     background-color: #38a9ff;
//     -webkit-transition: opacity height 0.2s ease-out;
//     transition: opacity height 0.2s ease-out;
// }*/

#lobby .game-types .btnGameType .btnGameTypeIcon, #tournament .menu-item .menu-item-Icon, #tournament .btnBack .menu-item-Icon {
	position: absolute;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
    transition: 0.5s ease;
}

#lobby .game-types .btnGameType .btnGameTypeText, #tournament .menu-item .menu-item-Text, #tournament .btnBack .menu-item-Text {
	display: none;
	bottom: 0px;
	position: absolute;
	font-size: 0.8em;
	width: 100%;
	text-align: center;
}

// #lobby .game-types .btnGameType.btnActive .btnGameTypeIcon {
//     background-position-y: -8px;
// }

// /*
// #lobby .game-types .btnGameType.btnCashGames.btnActive .btnGameTypeText, #lobby .game-types .btnGameType.btnSitNGo.btnActive .btnGameTypeText, #lobby .game-types .btnGameType.btnTournaments.btnActive .btnGameTypeText, #lobby .game-types .btnGameType.btnPrivateGame.btnActive .btnGameTypeText, #tournament .menu-item .menu-item-Text, #tournament .btnBack .menu-item-Text {
// 	display: block;
// 	bottom: 4px;
// }

// #tournament a.menu-item.btnBack .menu-item-Icon {
// 	// background-image: url("../assets/cashier-icon.png");
// }


// #lobby .game-types .btnGameType.btnCashGames .btnGameTypeIcon, #tournament .menu-item.prize-pool-menu .menu-item-Icon {
// 	// background-image: url("../assets/casino-icon.png");
// }

// #lobby .game-types .btnGameType.btnCashGames.btnActive .btnGameTypeIcon, #tournament .menu-item.prize-pool-menu.btnActive .menu-item-Icon {
// 	// background-image: url("../assets/casino-icon-on.png");
// }

// #lobby .game-types .btnGameType.btnSitNGo .btnGameTypeIcon, #tournament .menu-item.players-menu .menu-item-Icon {
// 	// background-image: url("../assets/clubs-icon.png");
// }

// #lobby .game-types .btnGameType.btnSitNGo.btnActive .btnGameTypeIcon, #tournament .menu-item.players-menu.btnActive .menu-item-Icon {
// 	// background-image: url("../assets/clubs-icon-on.png");
// }

// #lobby .game-types .btnGameType.btnTournaments .btnGameTypeIcon, #tournament .menu-item.levels-menu .menu-item-Icon {
// 	// background-image: url("../assets/tournaments-icon.png");
// }

// #lobby .game-types .btnGameType.btnTournaments.btnActive .btnGameTypeIcon, #tournament .menu-item.levels-menu.btnActive .menu-item-Icon {
// 	// background-image: url("../assets/tournaments-icon-on.png");
// }

// #lobby .game-types .btnGameType.btnPrivateGame .btnGameTypeIcon {
// 	// background-image: url("../assets/battle-icon.png");
// }

// #lobby .game-types .btnGameType.btnPrivateGame.btnActive .btnGameTypeIcon {
// 	// background-image: url("../assets/battle-icon-on.png");
// }

// #tournament .menu-item.details-menu .menu-item-Icon {
// 	// background-image: url("../assets/leagues-icon.png");
// }

// #tournament .menu-item.details-menu.btnActive .menu-item-Icon {
// 	// background-image: url("../assets/leagues-icon-on.png");
// }

// */


.fixed-header .chipsIndicator, .fixed-header .gemsIndicator {
	position: absolute;
    top: 17px;
	width: 140px;
}

.mobile.landscape .fixed-header .chipsIndicator, .mobile.landscape .fixed-header .gemsIndicator {
    transform: scale(0.8);
}

.fixed-header .chipsIndicator {
    right: 10px;
}

.fixed-header .gemsIndicator {
    right: -10px;
}

.fixed-header .chipsIndicator .icon, .fixed-header .gemsIndicator .icon {
	display: inline-block;
	width: 30px;
	height: 30px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.fixed-header .chipsIndicator .icon {
	background-image: url("../chips-icon-small.png");
}

.fixed-header .gemsIndicator .icon {
	// background-image: url("../assets/diamonds-icon-small.png");
}

.fixed-header .chipsIndicator .value, .fixed-header .gemsIndicator .value {
	display: inline-block;
	font-size: 18px;
    position: relative;
    top: -9px;
	color: white;
	margin-left: 5px;
}

// #lobby .game-groups {
// 	list-style: none;
//     padding: 0;
//     margin: 0;
//     display: block;
//     -webkit-box-pack: center;
//     justify-content: center;
// 	font-size: 14px;
//     /*max-width: 1000px;*/    
// 	// background-image: url(../assets/top-bar-basic.png);
//     background-size: 100% 138%;
//     background-repeat: no-repeat;
// 	padding-top: 2px;
// }

// #lobby .game-groups .gameGroupRow {
// 	display: inline-block;
// }

// /*
// #lobby .game-groups .btnGameGroup, #lobby .game-groups .joinPrivateGame, #lobby .game-groups .createPrivateGame, #lobby .game-groups .lobbyPrivateGame { 
// 	display: inline-block;
// 	width: 184px;
//     text-align: center;
//     padding-top: 10px;
//     padding-bottom: 10px;
// 	position: relative;
// 	// background-image: url("../assets/tab-off.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }

// #lobby .game-groups .btnGameGroup.btnActive, #tournament .menu-item.btnActive, #lobby .game-groups .joinPrivateGame.btnActive, #lobby .game-groups .createPrivateGame.btnActive, #lobby .game-groups .lobbyPrivateGame.btnActive {
// 	// background-image: url("../assets/tab-on.png");
// }
// */


// /*#lobby .game-groups .btnGameGroup::before {
//     content: "";
//     display: block;
//     position: absolute;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     height: 0;
//     opacity: 0;
//     background-color: #38a9ff;
//     -webkit-transition: opacity height 0.2s ease-out;
//     transition: opacity height 0.2s ease-out;
// }*/

// #lobby .btnActive {
// 	color: #fff;
// }

// #lobby .btnActive::before{
// 	height: 2px !important;
//     opacity: 1 !important;
// }

#club a {
	color: #fff;
	text-decoration: none;
}


// #club .sticky {
// 	position: fixed;
// }

// #club h2 {
// 	text-shadow: 2px 2px 4px #000000;
// 	font-size: 20px;
//     color: lightgray;
// }

// #Connectivity {
// 	position: fixed;
//     width: 100vw;
//     height: 100vh;
//     background-color: rgba(0,0,0,0.9);
//     z-index: 99;
// 	color: #fff;
// 	padding-top: 45vh;
// }

// #Connectivity h1{
//     display: block;
//     text-align: center;
// }

// #Connectivity p{
//     display: block;
//     text-align: center;
// }

// #control-bar {
// 	width: 100vw;
// 	height: 50px;
// 	display: none;
// 	top: 0;
// 	left: 0;
// 	z-index: 5;
// 	border-bottom: 1px solid #35383f;
// 	background: #000;
// }

// .ep-plugin #control-bar {
// 	width: 100%;
// }

// .html5-games-open #control-bar {
// 	position: absolute;

// }

// .html5-games-open.mobile.landscape #control-bar {
// 	width: 0px;
//     border: none;
// }

#control-bar .frame {
	width: 80vw;
	overflow-x: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	-webkit-overflow-scrolling: touch;
	display: inline-block;
	flex: 1;
}

// #control-bar .main-menu {
// 	width: 200px;
// 	height: calc(100vh - 50px);
// 	background: rgba(0,0,0,0.8);
// 	left: -210px;
// 	position: relative;
// 	-webkit-transition: all 0.5s ease-out;
// 	z-index: 9;
// }

// #control-bar .main-menu ul {
// 	list-style-type:none;
// 	padding: 0px;
// 	margin: 0px;
// }

// #control-bar .main-menu ul li{
// 	text-align: left;
// 	padding-left: 40px;
// 	padding-right: 20px;
// }

// #control-bar .main-menu ul li a {
// 	color: #fff;
// 	font-size: 22px;
// 	line-height: 30px;
// 	width: 100%;
// 	padding-left: 10px;
// }

// .html5-menu-open #control-bar .main-menu {
// 	-webkit-transition: all 0.5s ease-out;
// 	left: 0px;
// }


// #control-bar .menu {
// 	float: left;
// 	text-align: center;
// 	background: rgba(0,0,0,0.5);
// 	width: 60px;
// }

// #control-bar .menu i {
// 	width: 60px;
// }

// html:not(.html5-menu-open) #control-bar .menu .m-close {
// 	display: none;
// }

// html:not(.html5-games-open) #control-bar .menu, html:not(.html5-games-open) #control-bar .btnNewTable {
// 	display: none;
// }


// .html5-games-open body, .html5-games-open #lobby {
// 	height: 0;
// 	overflow-y: hidden;
// }

// .ep-plugin.html5-games-open body, .ep-plugin.html5-games-open #lobby {
// 	height: 100%;
//     overflow-y: scroll;
// }

// .html5-menu-open #control-bar .menu .m-open {
// 	display: none;
// }



// #control-bar .menu a {
// 	width: 60px;
// 	height: 40px;
// 	display: inline-block;
// 	padding-top: 10px;
// 	font-size: 1.5em;
// 	cursor: pointer;
// }

// #control-bar .frame::-webkit-scrollbar { 
// 	display: none;
// }


// #control-bar .games {
// 	width: max-content;
// }
// #control-bar .games .table {
// 	// background-image: url(/html5/assets/table-grey-icon.png);
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// 	background-position-x: center;
// 	display: inline-block;
// 	width: 86px;
// 	height: 49px;
// 	text-align: center;
// 	margin-bottom: 0;
// 	float: left;
// 	overflow: hidden;
// 	position: relative;
// }

// #control-bar .games .table.tnm.active {
// 	// background-image: url(/html5/assets/table-blue-icon.png);
// }

// #control-bar .games .table.sng.active {
// 	// background-image: url(/html5/assets/table-red-icon.png);
// }

// #control-bar .games .table.active {
// 	// background-image: url(/html5/assets/table-green-icon.png);
// }


// #control-bar .games .table .cards {
// 	margin-top: 11px;
//     height: 34px;
// }

// #control-bar .games .table .card {
// 	background: #fff;
//     width: 25px;
//     height: 30px;
//     display: inline-block;
//     margin: 50px 2px 2px 2px;
//     border-radius: 2px 2px 0px 0px;
// 	position: relative;
// 	-webkit-box-shadow: none;
// 	-moz-box-shadow: none;
// 	box-shadow: none;
// }

// #control-bar .games .table .card.red .num, #control-bar .games .table .card.red .suit {
// 	color: red;
// }


// #control-bar .games .table .card .num {
// 	position: absolute;
//     top: -1px;
//     left: 0px;
//     width: 20px;
//     height: 18px;
//     font-size: 18px;
//     color: #000;
//     line-height: 16px;
//     text-align: left;
//     letter-spacing: -2px;
// }

// #control-bar .games .table .card .suit {
// 	position: absolute;
//     bottom: -3px;
//     right: -1px;
//     width: 16px;
//     height: 18px;
//     font-size: 15px;
//     color: #000;
//     line-height: 16px;
//     text-align: right;
// }

// #control-bar .games .table .card .folded {
// 	display: block;
//     position: absolute;
//     top: -1px;
//     left: -1px;
//     width: 25px;
//     height: 33px;
//     background: rgba(0,0,0, 0.6);
// 	border-radius: 2px 2px 0px 0px;
// }

// #control-bar .games .table .alertBar {
//     width: 100%;
//     height: 2px;
// 	position: relative;
// }

// .html5-games-open.mobile #control-bar .games .table .alertBar {
// 	position: absolute;
//     bottom: 0px;
// }

// #control-bar .games .table .alertBar.blue {
// 	background: #38a9ff;
// }

// #control-bar .games .table .alertBar.red {
// 	background: #ff0000;
// }

// #control-bar .games .table .alertBar.green {
// 	background: #00ff00;
// }

// #control-bar .games .table .alertBar.stopped {
// 	background: #ffa500;
// }

// #control-bar .games .table i {
// 	margin-top: 14px;
// }

.profile-button {
	display: block;
	position: absolute;
	z-index: 4;
    left: 26px;
    top: calc(50% - 18px);
	// background-image: url("../assets/home-player-bg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	height: 46px;
    min-width: 160px;
	max-width: 260px;
}

.profile-button .profile-avatar {
	display: inline-block;
	position: relative;
	height: 50px;
	width: 50px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
    border: 1px solid;
	border-radius: 25px;
    text-align: center;
	top: -3px;
	left: -10px;
    background-color: black;
    font-size: 32px;
}

.profile-button .profile-name {
	display: inline-block;
	position: relative;
    bottom: 20px;
	left: -10px;
	font-size: 20px;
	color: white;
	padding-left: 10px;
}

// /*
// #privateGame {
// 	top: 0;
// 	background-color: rgba(30,32,36,0.9);
// 	z-index: 5;
// }

// #privateGame .privateGame-window {
// 	width: 90vw;
//     height: 85vh;
//     position: relative;
//     left: calc(50% - 45vw);
//     top: calc(50% - 44vh);
//     // background-image: linear-gradient(to right, rgba(60,60,60,0), rgba(60,60,60,0.9), rgba(60,60,60,0.9), rgba(60,60,60,0));
//     border: 8px solid rgb(62,60,60,1);
//     border-radius: 7px;
// }
// */

// /*#privateGame .privateGame-window .frame {
// 	// background-image: url("../assets/icon-menu-bg-off.png");
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
// }*/

// /*
// #privateGame .privateGame-window .header, #tournament .tournament-menu {
// 	// background-image: url("../assets/top-bar-basic.png");
//     background-size: 100% 134%;
//     background-repeat: no-repeat;
//     height: 78px;
// }

// #privateGame .privateGame-window .btnSaveGame {
// 	position: absolute;
//     top: 5px;
//     right: 10px;
// 	// background-image: url("../assets/button-blue.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// 	width: 120px;
// 	height: 40px;
// 	text-align: center;
// 	line-height: 2.4;
// 	color: white;
// }

// #privateGame .privateGame-window .frame {
// 	height: 100%;
// }

// #privateGame .privateGame-window .contents {
// 	height: calc(100% - 70px);
//     overflow-y: auto;
// }

// #privateGame .privateGame-window .contentsWindow {
// 	display: flex;
// }

// #privateGame .privateGame-window .privateGameCol {
// 	display: inline-block;
// 	flex: 1;
// 	margin: 14px;
// }

// #privateGame .privateGame-window .createPrivateCol {
// 	display: flex;
// 	margin-bottom: 6px;
// }

// #privateGame .privateGame-window .privateGameLabel, #privateGame .privateGame-window .privateGameInput {
// 	display: inline-block;
// }

// #privateGame .privateGame-window .privateGameLabel {
// 	flex: 0.3;
//     text-align: right;
//     padding-right: 6px;
// 	line-height: 2.3;
// }

// .mobile.landscape #privateGame .privateGame-window .privateGameLabel {
// 	flex: 0.4;
// }

// .mobile.landscape #privateGame .privateGame-window .privateGameInput {
// 	flex: 0.6;
// }

// #privateGame .privateGame-window .privateGameInput {
// 	flex: 0.7;
// }

// #tournament .tournament-menu-buttons {
// 	width: 100%;
//     display: flex;
// }


// #privateGame .privateGame-window .header .headerTitle {
// 	text-align: center;
//     font-size: 26px;
//     position: absolute;
//     right: calc(50% - 93px);
//     line-height: 2;
// 	color: white;
// }

// #lobby .contents .contents-item.privateCol {
// 	display: inline-block;
//     position: relative;
//     height: calc(85vh - 100px);
//     width: 49%;
// }
// */

// #lobby .contents .contents-item a {
// 	display: block;
// 	position: relative;
// 	// background-image: url("../assets/icon-menu-bg.png");
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     margin: auto;
//     max-width: 586px;
//     max-height: 880px;
//     width: 100%;
//     height: calc(42vw * 1.5);
//     /*height: 100%;*/
//     /* padding-bottom: 150%;*/
// }

// #lobby .contents .contents-item a .btnText {
// 	position: absolute;
//     top: 77%;
//     width: 80%;
//     height: 30%;
// 	max-height: 80px;
//     text-align: center;
//     // background-image: url(../assets/home-player-bg.png);
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     left: 10%
// }

// #lobby .contents .contents-item a .btnText label {
// 	text-align: center;
// 	vertical-align: middle;
//     line-height: 80px;
//     color: white;
//     font-size: 36px;
// }

// #lobby .contents .contents-item a .btnImage {
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
// 	height: 100%;
// }

// #lobby .contents .contents-item a.createGame .btnImage {
// 	// background-image: url("../assets/domino.png");
// }

// #lobby .contents .contents-item a.joinGame .btnImage {
// 	// background-image: url("../assets/texas-holdem.png");
// }

// #lobby .contents .contents-item a .btnHighLight {
// 	// background-image: url("../assets/icon-menu-bg-off.png");
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
// 	height: 100%;
// }

// #lobby .contents .contents-item a .btnHighLight:hover {
// 	// background-image: url("../assets/icon-menu-bg-on.png");
// }

// .joinGame {
// 	display: none;
//     position: absolute;
//     width: 400px;
//     height: 160px;
//     top: calc(50% - 75px);
//     right: calc(50% - 200px);
// }

// .joinGame.enabled {
// 	display: block;
// 	transition: 1s;
// 	top: calc(50% - 80px);
// }

// .joinGame input {
//     position: relative;
//     width: 90%;
//     left: 5%;
//     background-color: white;
//     border-radius: 6px;
// }

// .joinGame #joinGameID {
// 	top: 14px;	
// }

// .joinGame #joinPassword {
// 	top: 19px;	
// }

// #club .joinGame .btnjoinGame.cancel, #club .joinGame .btnjoinGame.confirm {
// 	display: inline-block;
//     position: relative;
//     width: 130px;
//     height: 40px;
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     text-align: center;
//     color: white;
//     line-height: 2.4;
//     margin-right: 16px;
//     margin-left: 16px;
//     margin-top: 10px;
//     top: 14px;
// }

// #club .joinGame .btnjoinGame.cancel {
// 	// background-image: url("../assets/button-red.png");
// 	float: left;
//     margin-right: 0;
// }

// #club .joinGame .btnjoinGame.confirm {
// 	// background-image: url("../assets/button-green3.png");
// 	float: right;
// 	margin-left: 0;
// }

// #club #lobby .privateGameCol {
// 	display: inline-block;
// }

// #club #lobby .privateGameCol.nameCol {
// 	font-size: 1em;
//     text-align: right;
//     flex: 0.2;
//     margin-right: 10px;
//     top: 6px;
//     position: relative;
// 	height: calc(100% - 6px);
// }

// #club #lobby .privateGameCol.inputFieldsCol {
// 	flex: 1;
// }

// #club #lobby .privateGameCol.nameCol .privateGameLabel {
// 	height: 36.5px;
// 	margin-bottom: 4px;
// }

// #club #lobby .privateGameCol.inputFieldsCol .privateGameInput {
// 	margin-bottom: 4px;
// 	margin-top: 10px;
// }

// #club #lobby .privateGameCol.inputFieldsCol .btnplayMoney {
// 	width: 100px;
//     display: block;
//     height: 36.5px;
//     background-color: deepskyblue;
//     color: black;
//     text-align: center;
//     line-height: 2.4;
// }

// #club #lobby .privateGameCol .privateColItem {
//     flex: 1;
//     margin-bottom: -20px;
// }

// #club #lobby .privateGameCol .privateColItem label {
//     position: relative; 
// 	top: -58px; 
// 	display: inline;
// }

// #club #lobby .privateGameCol .privateColItem.high label {
// 	top: -62px; 
// }

// /* The switch - the box around the slider */
// .switch {
// 	position: relative;
// 	display: block;
// 	width: 60px;
// 	height: 34px;
// }

// /* Hide default HTML checkbox */
// .switch input {
// 	opacity: 0;
// 	width: 0;
// 	height: 0;
// }

// /* The slider */
// .slider {
// 	position: absolute;
// 	cursor: pointer;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	background-color: rgba(255, 255, 255, 0.03);
// 	-webkit-transition: .4s;
// 	transition: .4s;
// }

// .slider:before {
// 	position: absolute;
// 	content: "";
// 	height: 26px;
// 	width: 26px;
// 	left: 4px;
// 	bottom: 4px;
// 	background-color: white;
// 	-webkit-transition: .4s;
// 	transition: .4s;
// }

// input:checked + .slider {
// 	background-color: orange;
// }

// input:focus + .slider {
// 	box-shadow: 0 0 1px orange;
// }

// input:checked + .slider:before {
// 	-webkit-transform: translateX(26px);
// 	-ms-transform: translateX(26px);
// 	transform: translateX(26px);
// }

// /* Rounded sliders */
// .slider.round {
// 	border-radius: 34px;
//     border: 1px solid rgba(255, 255, 255, 0.1);
// }

// .slider.round:before {
// 	border-radius: 50%;
//     top: 3px;
// }

#club #lobby .frame {
	height: 100%;
}

// #club #lobby .contents {
// 	height: calc(100% - 136px);
// 	display: block;
// }

// #club #lobby .contents.createPrivateGame {
// 	margin-top: 32px;
//     padding-bottom: 20px;
//     display: block;
// }

// #club #lobby .contentsWindow {
// 	position: relative;
//     /* left: 5%; */
//     width: 90%;
//     display: flex;
//     // background-image: url(../assets/home-button-bg.png);
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     max-width: 540px;
//     margin: auto;
//     padding: 20px;
//     padding-bottom: 60px;
//     border: 4px solid black;
//     border-radius: 8px;
// }

// #club #lobby .createPrivateCol {
// 	display: flex;
// }

// #club #lobby .createPrivateCol .pairedInput {
// 	display: inline-block;
// 	flex: 1;
// }

// .popup .header {
// 	display: flex;
// 	// background-image: url("../assets/top-bar.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
//     height: 70px;
// 	background-position: center;
// }

// .mobile .popup .header {
// 	background-size: auto 100%;
// }

// .popup .header-item {
// 	display: inline-block;
//     flex: 3;
//     -webkit-box-flex: 3;
//     flex-grow: 3;
//     text-align: center;
//     padding-top: 10px;
//     padding-bottom: 10px;
//     /*border-bottom: 1px solid #35383f;
//     border-right: 1px solid #35383f;*/
//     color: white;
//     line-height: 34px;
// 	font-size: 28px
// }

// .popup .header-item.small {
// 	flex: 1.5;
// }

// .popup .header-item.btnBack, #privateGame .header-item.btnBack, #tournament .menu-item-back {
// 	width: 46px;
// 	height: 46px;
// 	// background-image: url("../assets/button-back.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// 	position: absolute;
// 	left: 20px;
// 	top: 2px;
// } 

// #tournament .menu-item-back {
//     top: 12px;
//     left: 13px;
// }

// .mobile #tournament .menu-item-back {
//     transform: scale(0.8);
//     top: -3px;
//     left: 3px;
// }

// .popup .header-item.btnRealMoney {
// 	position: absolute;
//     right: 10px;
//     line-height: 1.3;
//     font-size: 15px;
//     // background-image: url(../assets/button-blue2.png);
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     top: 10px;
//     padding-right: 6px;
//     padding-top: 6px;
//     padding-bottom: 6px;
//     padding-left: 8px;
// }

// #lobby .contents .playerInfo-item.realMoney, #privateGame .contents .playerInfo-item.realMoney {
// 	// background-image: url(../assets/button-blue2.png);
//     background-size: 100% 100%;
//     background-repeat: no-repeat;
//     max-width: 140px;
//     height: 36.5px;
//     padding: 6px;
// }

// #lobby .contents.inputFieldsCol .privateGameInput {
// 	margin-bottom: 4px;
// }

// #lobby .contents .btnCreateGame, #lobby .contents .btnDeleteGame {
// 	position: relative;
//     left: calc(50% - 125px);
//     width: 250px;
//     height: 50px;
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
//     display: block;
//     text-align: center;
//     line-height: 3;
// 	top: -65px;
// }

// #lobby .contents .btnCreateGame {
//     color: black;
// 	// background-image: url("../assets/button-green3.png");
// }

// #lobby .contents .btnDeleteGame {
//     color: white;
// 	// background-image: url("../assets/button-red.png");
// }

// .popup .header-item.btnRealMoney .checked, .popup .contents .playerInfo-item.value a .checked, #lobby .contents .playerInfo-item.value a .checked, #privateGame .contents .playerInfo-item.value a .checked {
// 	// background-image: url(../assets/button-off.png);
//     background-size: 100% 100%;
//     position: relative;
//     width: 6px;
//     height: 20px;
//     display: inline-block;
//     float: left;
//     margin-right: 4px;
// }

// #privateGame .contents .playerInfo-item.value a .checked {
// 	top: 2px;
// }

// #lobby .contents .playerInfo-item.value a .btnText {
// 	position: relative;
// 	left: 8px;
// 	color: white;
// 	top: 2px;
// }

// .popup .header-item.btnRealMoney .btnText, .popup .contents .playerInfo-item.value a .btnText, #lobby .contents .playerInfo-item.value a .btnText, #privateGame .contents .playerInfo-item.value a .btnText {
// 	display: inline-block;
// }

// #privateGame .contents .playerInfo-item.value a .btnText {
// 	position: relative;
//     left: 6px;
//     top: 1px;
// 	color: white;
// 	font-size: 0.9em;
// }

// #lobby .contents .playerInfo-item.value a .checked {
// 	left: 6px;
//     top: 2px;
//     height: 21px;
//     width: 8px;
//     border: 1px solid black;
// }

// #profile .contents .playerInfo-item.value a .checked {
//     left: 6px;
//     top: 4px;
//     height: 16px;
// }

// .popup .header-item.btnRealMoney.enabled .checked, .popup .contents .playerInfo-item.value a.enabled .checked, #lobby .contents .playerInfo-item.value a.enabled .checked, #privateGame .contents .playerInfo-item.value a.enabled .checked {
// 	// background-image: url("../assets/button-on.png");
// }

// .popup .contents-item {
// 	display: inline-block;
// 	/*height: 84vh;*/
// }


// .popup .contents-item.playerInfo {
// 	width: 70vw;
// 	position: absolute;
// }


// #profile .contents .btnLogout {
//     color: white;
//     width: 90%;
//     margin: 0px auto;
//     padding: 4px 4px 4px 4px;
//     display: block;
//     text-align: center;
//     max-width: 200px;
// 	// background-image: url("../assets/button-blue2.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }


// #profile .contents .btnCashier {
//     color: black;
//     width: 90%;
//     margin: 0px auto;
//     padding: 4px 4px 4px 4px;
//     display: block;
//     text-align: center;
//     max-width: 200px;
// 	// background-image: url("../assets/button-yellow.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// }

// .popup .footer {
// 	// background-image: url("../assets/horizontal-line.png");
// 	background-size: 100% 100%;
// 	background-repeat: no-repeat;
// 	width: 100vw;
//     height: 4px;
// }

// .mobile.landscape #profile .footer {
// 	display: none;
// }

// .html5-games {
// 	width: 100vw;
// 	height: 100vh;
// 	position: fixed;
// 	display: none;
// 	z-index: 4;
// 	top: 0;
// 	left: 0;
// }

// .ep-plugin .html5-games, .ep-plugin .html5-games #games { 
// 	width: 100%;
// }


// .html5-games-open .html5-games, #control-bar.has-games {
// 	display: flex;
// }

// .html5-games iframe {
// 	width: 100vw;
// 	height: 100vh;
// 	background: #000;
// }

// .ep-plugin .html5-games iframe {
// 	width: 100%;
//     height: 100%;
// }
	
// .html5-games-open.desktop .html5-games iframe, .html5-games-open.tablet .html5-games iframe, .html5-games-open.mobile.portrait .html5-games iframe {
// 	height: calc(100vh - 50px);
// }

// .ep-plugin.html5-games-open.desktop .html5-games iframe, .ep-plugin.html5-games-open.tablet .html5-games iframe, .ep-plugin.html5-games-open.mobile.portrait .html5-games iframe {
// 	height: 100%;
// }

// .html5-games-open.desktop .html5-games, .html5-games-open.tablet .html5-games, .html5-games-open.mobile.portrait .html5-games {
// 	height: calc(100vh - 50px);
// 	top: 50px;
// }

// .ep-plugin.html5-games-open.desktop .html5-games, .ep-plugin.html5-games-open.tablet .html5-games, .ep-plugin.html5-games-open.mobile.portrait .html5-games {
// 	height: calc(100% - 50px);
// }

// .html5-games-open.desktop #control-bar .menu, .html5-games-open.desktop #control-bar .frame, .html5-games-open.tablet #control-bar .menu, .html5-games-open.tablet #control-bar .frame, .html5-games-open.mobile.portrait #control-bar .menu, .html5-games-open.mobile.portrait #control-bar .frame {
// 	background: rgba(0,0,0,1);
// }

// .html5-games-open.mobile.landscape #control-bar .frame {
// 	background: none;
// 	width: 86px;
// 	overflow-y: hidden;
// 	display: block;
// 	position: absolute;
// 	top: 48px;
// 	height: 150px;
// 	vertical-align: bottom;
// }

// .html5-games-open.mobile.landscape #control-bar .games {
// 	width: auto;
// 	bottom: 0;
// 	/*position: absolute;*/
// }

// .html5-games-open.mobile.landscape #control-bar .menu {
// 	background: none;
// }

// .html5-games-open.mobile.landscape #control-bar .menu a {
// 	position: relative;
// 	z-index: 10;
// }

// .html5-games-open.mobile.landscape #control-bar .main-menu {
// 	height: 100vh;
// 	margin-top: -50px;
// 	padding-top: 40px;
// }

.mobile #club .fixed-body .lobby .game {
	padding: 0px 26px 0px 26px;
}

// .mobile #club .tableFrame tr {
// 	padding: 8px 30px 8px 30px;
// }



// /* HOME LARGE ICONS */

// #home .horizontal-scroll {
// 	margin-top: calc(50vh - 28%);
// 	text-align: center;
// }

// .mobile.portrait #home .horizontal-scroll {
//     margin-top: 0;
//     width: 100vw !important;
// }

// .home-large-icon {
// 	position: relative;
//     display: inline-block;
//     max-width: calc(100vw / 3.7);
// 	width: 100%;
// }

// .home-large-icon img {
// 	width: 100%;
// }

// .home-large-icon .abs {
// 	position: absolute;
// }
// .carousel-item .frame {
// 	position: absolute;
//     top: 0px;
//     object-fit: contain;
//     width: 100%;
//     padding-right: 15px;
//     padding-left: 15px;
//     margin-left: -15px;

// }
// .carousel-item img {
// 	max-height: 84vh;
// }

// html.mobile.landscape .carousel-item img {
// 	max-height: 68vh;
// }

// html:not(.mobile) .v-center {
// 	position: absolute;
//     top: 50%;
//     -webkit-transform: translateY(-50%);
//     -ms-transform: translateY(-50%);
// 	transform: translateY(-50%);
// 	padding: 0;
// }

// .mobile.landscape .home-large-icon {
//     max-width: calc(100vw / 5);
//     margin-top: 16vh;
// }

// .mobile.landscape .ep-card .home-large-icon {
// 	max-width: calc(100vw / 5);
// 	margin-top: 0px;
// }

// .mobile.portrait .home-large-icon {
//     max-width: 49vw;
//     height: calc(49vw /0.7);
// }

// /* HOME LARGE ICONS END */


// /* EP CARD */


// .ep-card .info {
// 	background-color: #222;
//     border: 1px solid #333;
//     border-radius: 5px;
//     display: block;
//     width: 73vw;
//     margin: 0 auto;
//     min-height: calc(100vw / 3);
//     margin-top: 4vh;
//     float: right;
//     margin-right: 1vw;
//     padding: 10px 20px 20px 20px;
// }

// .ep-card .info sup {
// 	top: -1em;
// 	font-size: 40%;
// }

// .ep-card .info sub {
// 	bottom: 0em;
// 	font-size: 40%;
// }

// .mobile.landscape .ep-card .info {
// 	font-size: 12px;
// 	width: 80vw;
// 	min-height: calc(100vw / 4);
// 	margin-top: 2vh;
// }

// .ep-card .info  h1 {
// 	margin: 0px 0px -10px 0px;
// 	text-shadow: 2px 2px 4px #000000;
//     color: lightgray;
// }
// .mobile.landscape .ep-card .info h1 {
// 	font-size: 20px;
// }

// .ep-card .info hr {
// 	border-top: 1px solid #333;
// }

// .mobile.landscape .ep-card .info hr {
// 	margin: 10px 0px 6px 0px;
// }

// .ep-card.full-width .info {
// 	width: 98vw;
//     margin-top: 1vw;
// 	min-height: auto;
// }

// .mobile.landscape .ep-card.full-width .info {
// 	width: 98vw;
//     margin-top: 2vw;
//     min-height: auto;
// }

// /* EP CARD END */


// /* CLUB DETAILS */


.mobile #club .fixed-body {
	padding-bottom: 0px;
}

.mobile.landscape .ep-card {
	margin-top: -12px;
}

// @media screen and (max-width: 1023px) {
// 	#home .horizontal-scroll {
// 		margin-top: calc(50vh - 30%);
// 	}
// 	.ep-card .info {
// 		font-size: 14px;
// 	}
// 	.ep-card .info h1 {
// 		font-size: 30px;
// 	}
// 	.ep-card .info hr {
// 		margin: 10px 0px 10px 0px;
// 	}

// }

// @media screen and (max-width: 768px) {
	
// 	.ep-card .info {
// 		font-size: 12px;
// 		min-height: calc(100vw / 4);
// 	}
// 	.ep-card .info h1 {
// 		font-size: 20px;
// 	}
// 	.ep-card .info hr {
// 		margin: 10px 0px 0px 0px;
// 	}

// }


// @media screen and (max-width: 660px) {
	
// 	.ep-card .home-large-icon {
// 		display: none;
// 	}
// 	.ep-card .info {
// 		margin-right: 1vw;
// 		font-size: 16px;
// 		min-height: calc(100vw / 4);
// 		width: 98vw;
// 		margin-top: 1vh;
// 	}
// 	.ep-card .info h1 {
// 		font-size: 30px;
// 	}
// 	.ep-card .info hr {
// 		margin: 10px 0px 10px 0px;
// 	}

// }

// /* CLUB DETAILS END */


/* Landscape */
// @media screen and (max-width: 900px) {
	
// 	.mobile #club .fixed-body .lobby .game {
// 		padding: 0px 36px 0px 36px;
// 	}
	
// 	.mobile #lobby .game-groups .btnGameGroup, .mobile #lobby .game-groups .joinPrivateGame, .mobile #lobby .game-groups .createPrivateGame {
//     	width: 142px;
// 	}
	
// 	.mobile #club #lobby .contents {
// 		position: relative;
// 		margin-top: 4px;
// 	}
	
// 	.mobile #club #lobby .privateGameCol.nameCol {
// 		flex: 0.34;
// 	}
	
// 	.mobile #lobby .privateGame-window {
//     	height: 93vh;
// 	}
// }

// @media screen and (max-width: 750px) {
	
// 	.mobile.landscape .fixed-header .chipsIndicator, .mobile.landscape .fixed-header .gemsIndicator {
// 		transform: scale(0.7);
// 		width: 170px;
// 	}
	
// 	.mobile.landscape .fixed-header .chipsIndicator {
//     	right: 110px;
// 	}
	
// 	.mobile.landscape .fixed-header .gemsIndicator {
//     	right: -10px;
// 	}
	
	
// 	/*.mobile #profile .contents .playerInfo-item {
//     	width: 54%;
// 	}
	
// 	.mobile #profile .contents .playerInfo-item.title {
//     	width: 42%;
// 	}*/
	
// }

// @media screen and (max-height: 360px) {
	
// 	.mobile #lobby .contents .btnCreateGame {
// 		position: absolute;
// 		top: -60px;
// 		left: 71vw;
// 		width: 70px;
// 		height: 50px;
// 	}
// }

// @media screen and (max-width: 600px) {
// 	.mobile #profile .contents-item.playerInfo {
//     	width: 80vw;
//     	right: 0px;
// 	}
	
// 	.mobile #privateGame .privateGame-window .privateGameLabel {
// 		flex: 1;
// 	}
	
// 	.mobile #privateGame .privateGame-window .privateGameInput {
// 		flex: 1;
// 	}
	
// 	.mobile #privateGame .contents .playerInfo-item.value a .checked {
// 		top: -21px;
// 	}
// }

// /* portrait */
// @media screen and (max-width: 500px) {
	
// 	.mobile #club .fixed-body .lobby .game {
// 		padding: 0px 10px 0px 10px;
// 	}
	
	
// 	.mobile .fixed-body .lobby.games.sitngo .game .title, #tournament #tnmTableHeader th, .mobile .fixed-body .lobby.games.tournament .game .title, #tournament #tnmTableHeader th{
//    		width: 65%;		
// 	}
	
	.mobile .fixed-body .lobby.games.cashgame .game .title, #tournament #tnmTableHeader th {
   		width: 100%;		
	}
	
// 	.mobile .fixed-body .lobby .game .frame label {
// 		font-size: 9px;
// 	}
	
// 	.mobile .fixed-body .lobby .game .frame span {
// 		font-size: 14px;
// 	}
	
// 	/*.mobile #lobby .games .frame {
// 		display: block;
// 	}*/
	
// 	.mobile .fixed-body .lobby.games .frameCol{
// 		display: inline-block;
// 	}
	
// 	.mobile .fixed-body .lobby.games .frameCol:last-of-type {
// 		flex: 0.3;
// 	}
	
// 	.mobile .fixed-body .lobby.games .frameRow{
// 		display: flex;
// 	}
	
// 	.mobile .fixed-body .lobby.games .frameRowCol{
// 		display: inline-block;
// 		flex: 1;
// 	}
	
// 	.mobile .fixed-header .chipsIndicator, .fixed-header .gemsIndicator {
//     	position: relative;
//     	top: 5px;
//     	display: inline-block;
// 	}
	
// 	.mobile .fixed-header .chipsIndicator {
// 		left: 10px;
// 		width: 50%;
// 	}
	
// 	.mobile .fixed-header .gemsIndicator {
// 		float: right;
// 		right: 0;
// 		width: 47%;
// 	}
	
// 	.mobile #lobby .game-types {
// 		display: flex;
// 	}
	
// 	.mobile #lobby .game-types .btnGameType {
// 		flex: 1;
// 		margin-right: 0;
// 	}
	
// 	.mobile #lobby .game-groups {
//     	padding-top: 8px;
// 	}
	
// 	.mobile.portrait #lobby .game-groups .gameGroupRow {
// 		display: flex;
// 	}
	
// 	.mobile.portrait #lobby .game-groups .btnGameGroup, .mobile.portrait #lobby .game-groups .joinPrivateGame, .mobile.portrait #lobby .game-groups .createPrivateGame, .mobile.portrait #lobby .game-groups .lobbyPrivateGame {
// 		flex: 1;
// 	}
	
// 	.mobile .fixed-footer {
// 		// background-image: url("../assets/top-bar-basic.png");
//     	background-size: 100% 120%;
//     	background-position-y: 10px;
		
// 	}
	
// 	.mobile .fixed-footer .gameLogoFrame {
// 		transform: scale(0.6);
//     	top: 0px;
// 	}
	
// 	.mobile.portrait .profile-button {
//     	background-size: 0;
// 	}
	
// 	.mobile.portrait .profile-button .profile-name {
// 		display: none;
// 	}
	
// 	.mobile.portrait #profile .header-item.btnRealMoney {
// 		display: none;
// 	}
	
// 	.mobile #profile .contents-item.avatarCol {
// 		display: block;
// 		height: auto;
// 		position: relative;
// 		left: 50%;
//     	width: 50%;
// 	}
	
// 	.mobile #profile .contents-item.avatar {
// 		width: 28vw;
// 		height: 28vw;
// 		transform: translate(-60%);
// 	}
	


// 	.mobile #profile .contents .playerInfo-row {
// 		padding-top: 0;
// 	}
	
// 	.mobile #profile .contents .playerInfo-col {
// 		display: block;
// 		width: 100%;
//     	padding-top: 12px;
// 	}
	
// 	.mobile #profile .contents-item.playerInfo {
// 		width: 100vw;
// 		padding: 0px 22px;
// 	}
		
// 	.mobile #profile .contents .playerInfo-item.email.title, .mobile #profile .contents .playerInfo-item.display.title {
// 		width: 32%;
// 	}
	
// 	.mobile #profile .contents .playerInfo-item.display.value {
// 		width: 64%;
// 	}

// 	.mobile #profile .contents .playerInfo-item.email.value {
// 		width: 64%;
// 	}
	
// 	.mobile #privateGame .header-item.btnBack {
// 		left: -20px;
// 		top: -24px;
// 	}
	
// 	.mobile #club #lobby .privateGameCol.inputFieldsCol {
// 		flex: 1;
// 		position: absolute;
// 		top: 30px;
// 	}
	
// 	.mobile #club #lobby .privateGameCol.nameCol {
// 		flex: 1;
//     	text-align: center;
// 	}
	
// 	.mobile #club #lobby .privateGameCol.nameCol .privateGameLabel, .mobile #club #lobby .privateGameCol.inputFieldsCol .privateGameInput {
// 		margin-bottom: 28px;
// 	}
	
// 	.mobile #club #lobby .privateGameCol.inputFieldsCol .privateGameInput {
// 		position: relative;
// 	}
	
// 	.mobile #club #lobby .privateGameCol.inputFieldsCol .privateGameInput.realMoney {
// 		left: calc(50% - 70px);
// 	}
	
// 	.mobile #lobby .contents .btnCreateGame {
// 		top: 10px;
// 	}
// }

.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.73333;
    font-size: 15px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s;
    transition: border-color ease-in-out .15s;
	height: calc(1.5em + .75rem + 2px);
}

.form-control:focus {
    border-color: #ffa800;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255,168,0,.25);
    background-color: rgba(255, 255, 255, 0.03);
    color: #fff;
}

// @media screen and (max-width: 900px) , screen and (max-height: 700px) {
// 	.html5-games-open.mobile #control-bar .games {
// 		top: 0;
// 		bottom: auto;
// 	}
	
// 	#control-bar .games .table .card .suit {
// 		font-size: 12px;
// 	}
// }

// /*
// // #Modal Login
// // --------------------------------------------------
// */


// .modal-content {
// 	background-color: #1e2024;
// }
	

// .modal--login .modal-header {
//   // background-image: url("../images/login_register_bg.jpg");
//   background-repeat: no-repeat;
//   background-position: 50% 50%;
//   background-size: cover;
//   border-radius: 0;
// }

// @media (min-width: 992px) {
//   .modal--login .modal-header {
//     height: 90px;
//   }
// }

// .modal--login .modal-header .close {
//   min-width: 33px;
//   width: 33px;
//   height: 33px;
//   padding: 0;
//   line-height: 33px;
//   border-radius: 50%;
//   background-color: #31404b;
//   color: #fff;
//   opacity: 1;
//   margin: 0;
//   text-shadow: none;
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   -webkit-transform: translate(50%, -50%);
//           transform: translate(50%, -50%);
//   -webkit-transition: all 0.3s ease;
//   transition: all 0.3s ease;
//   z-index: 1;
// }

// .modal--login .modal-header .close span {
//   margin-left: 2px;
// }

// .modal--login .modal-header .close:hover {
//   background-color: #1892ed;
// }

// .modal--login .modal-body {
//   padding: 0;
// }

// .modal--login .modal-account-holder {
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-orient: horizontal;
//   -webkit-box-direction: normal;
//       -ms-flex-direction: row;
//           flex-direction: row;
//   -ms-flex-wrap: wrap;
//       flex-wrap: wrap;
// }

// .modal--login .modal-account__item {
//   -ms-flex-preferred-size: 100%;
//       flex-basis: 100%;
//   padding: 40px;
//   padding-bottom: 50px;
// }


// .modal--login .modal-account__item-register-txt {
//   position: absolute;
//   left: 30px;
//   right: 40px;
//   top: 100%;
//   -webkit-transform: translateY(-100%) translateY(-30px);
//           transform: translateY(-100%) translateY(-30px);
// }

// .modal--login-only .modal-header {
//   height: 0;
//   padding: 0;
//   // background-image: none;
//   border: none;
// }

// .modal--login .loginFailed, #playerProfile .loginFailed {
// 	border: 1px solid orange;
// }

// .modal--login .emailErrorMsg, .modal--login .pswErrorMsg, .modal--login .regUserNameErrorMsg, .modal--login .regEmailErrorMsg, .modal--login .regPswErrorMsg, .modal--login .codeErrorMsg {
//     font-size: 10px;
//     color: orange;
//     top: -15px;
//     position: relative;
//     height: 0px;
// }

// .modal--login .codeErrorMsg {
//     top: -5px;
// }

// .modal--login .frmForgot .form-group-top {
// 	padding-top: 6px;
// }

// .modal--login .btnAuthForgotPsw {
// 	cursor: pointer;
//     font-size: 12px;
//     text-align: left;
//     padding: 0;
// }

// .modal--login .tab-forgot {
// 	height: 336px;
// }

// .modal--login .frmForgot {
//     display: none;
// }

// .modal--login .frmForgot.active {
//     display: block;
// }

// .modal--login .codeIps {
//     display: flex;
// }

// .modal--login .codeIp {
//     display: inline-block;
//     flex: 1;
//     text-align: center;
// }

// .modal--login .codeIp.dash {
//     text-align: center;
//     position: relative;
//     top: 5px;
// }

// .nav-tabs-login-wrapper {
//   margin-left: -20px;
//   margin-right: -20px;
//   margin-bottom: -20px;
//   margin-top: 20px;
// }

// @media (min-width: 768px) {
//   .nav-tabs-login-wrapper {
//     margin-left: -20px;
//     margin-right: -20px;
//     margin-bottom: -30px;
//     margin-top: 30px;
//   }
// }

// @media (min-width: 992px) {
//   .nav-tabs-login-wrapper {
//     margin-left: -38px;
//     margin-right: -38px;
//     margin-bottom: -50px;
//     margin-top: 30px;
//   }
// }

// .nav-tabs--login {
//   border-top: 1px solid rgba(255, 255, 255, 0.05);
// }

// .nav-tabs--login > li > a {
//   border: none !important;
//   border-radius: 0 !important;
//   font-size: 11px;
//   text-transform: uppercase;
//   font-family: "Montserrat", sans-serif;
//   font-weight: 700;
//   letter-spacing: -0.02em;
//   color: rgba(255, 255, 255, 0.4);
//   padding-top: 15px;
//   padding-bottom: 15px;
// }

// .nav-tabs--login > li > a:hover {
//   background-color: #292c31;
//   color: #38a9ff;
// }

// .nav-tabs--login > li.active > a {
//   color: #38a9ff;
//   background-color: #292c31;
// }

// .nav-tabs--login > li.active > a:hover {
//   color: #38a9ff;
//   background-color: #292c31;
// }

// .nav-tabs--login > li + li {
//   border-left: 1px solid rgba(255, 255, 255, 0.05);
// }

// @media screen and (orientation: landscape) {
	
// 	.mobile .profile-button {
//     	left: 16px;
// 	}
// }

// @media screen and (orientation: portrait) {
	
// 	/*
// 	html.ios.mobile #club {
// 		height: calc(100vh - 114px) !important;
// 		overflow: hidden;
// 	}
// 	*/
// }


/*<Avatar name="Foo Bar" />*/