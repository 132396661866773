body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#load_init {
  /* background-image: url('./assets/ploader.gif'); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 70px;
  width: 70px;
}

.frf-water {
  display: none !important;
}

.frf-trigger-button {
  padding: 10px 10px 10px !important;
  right: 0px !important;
  bottom: 5px !important;
}